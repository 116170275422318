import axios from "axios";
import { ref } from "vue";
import { useSnackbar } from "@/store";

export const useLoadMapBiomas = () => {
  const { showSnackbar } = useSnackbar();
  const unexpectedError =
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.";
  const loadingMapBiomas = ref(false);
  const mapBiomas = ref<any[]>([]);

  async function load() {
    try {
      loadingMapBiomas.value = true;
      const res = await axios.get("/mapbiomas");
      mapBiomas.value = res.data;
    } catch (err) {
      showSnackbar(unexpectedError, "error");
    } finally {
      loadingMapBiomas.value = false;
    }
  }

  return {
    loadingMapBiomas: loadingMapBiomas,
    mapbiomas: mapBiomas,
    load,
  };
};

<template>
  <v-card class="mt-5" style="height: 380px">
    <div class="card-content" style="height: 100%">
      <div
        class="row"
        style="
          margin: 0;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        "
      >
        <span class="card-title left" style="flex-grow: 1"
          >Curva histórica de vegetação</span
        >
      </div>
      <div v-if="dataset">
        <template v-if="hasDatasets">
          <div class="row">
            <canvas ref="chart1" style="height: 300px; width: 100%"></canvas>
          </div>
        </template>
        <template v-else>
          <div
            style="
              font-size: large;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <span>Ainda não há dados disponíveis</span>
            <v-btn @click="downloadSafra" class="mt-1">Baixar</v-btn>
          </div>
        </template>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

const props = defineProps<{
  caption: any;
  formatoDataGrafico?: string;
}>();

const chart1 = ref<HTMLCanvasElement | null>(null);
let chartInstance1: Chart | null = null;
const downloadingHistory = ref(false);

const dataset = computed(() =>
  Array.isArray(props.caption) ? props.caption[0] : props.caption
);
const hasDatasets = computed(
  () =>
    dataset.value && dataset.value.datasets && dataset.value.datasets.length > 0
);

const commonOptions = computed(() => ({
  tension: 0.1,
  borderJoinStyle: "round",
  plugins: {
    tooltip: {
      callbacks: {
        title: (context: any) => {
          const d = new Date(context[0].raw.x);
          return d.toLocaleDateString("pt-br", {
            month: "short",
            day: "numeric",
          });
        },
      },
    },
    legend: { display: true },
    datalabels: { display: false },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      time: {
        unit: "month",
        parser: props.formatoDataGrafico || "MM/dd/yyyy",
      },
      ticks: {
        callback: (value: any) => {
          const date = new Date(value);
          return new Intl.DateTimeFormat("pt-BR", { month: "short" })
            .format(date)
            .toUpperCase();
        },
      },
    },
    y: {
      beginAtZero: true,
    },
  },
}));

const initCharts = () => {
  if (dataset.value && hasDatasets.value && chart1.value) {
    chartInstance1 = new Chart(chart1.value.getContext("2d")!, {
      type: "line",
      data: dataset.value,
      options: commonOptions.value,
    });
  }
};

onMounted(() => {
  initCharts();
});

watch(
  () => props.caption,
  () => {
    chartInstance1?.destroy();
    initCharts();
  }
);

const refreshPage = () => {
  window.location.reload();
};

const downloadHistoric = () => {
  console.log("Download histórico solicitado");
};

const downloadSafra = () => {
  console.log("Download da safra solicitado");
};
</script>

<template>
  <div class="my-5">
    <v-card>
      <v-card-title class="text-h5 bg-primary font-weight-bold">
        Renda Presumida
      </v-card-title>
      <v-card-text class="my-2 mx-3 pa-3 text-center">
        <template v-if="data && data.income && data.score">
          <div class="my-8">
            <p class="text-h4" style="font-weight: bold">
              <span v-html="formatIncome(data.income)"></span>
            </p>
            <p class="text-h6">Classe {{ data.score }}</p>
          </div>
        </template>
        <template v-else>
          <p class="text-h4 text-grey">Dados não disponíveis</p>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
interface PersonalFinancialData {
  score: string;
  income: string;
}

defineProps<{
  data: PersonalFinancialData | null;
}>();

const formatIncome = (income: string): string => {
  const words = income.split(" ");
  if (words.length > 2) {
    const beforeLastWords = words.slice(0, -2).join(" ");
    const lastTwoWords = words.slice(-2).join(" ");
    return `${beforeLastWords}<br>${lastTwoWords}`;
  }
  return income;
};
</script>

<template>
  <v-container fluid class="bg-white">
    <CRUDTable
      v-model:create-form-data="createFormData"
      v-model:edit-form-data="editFormData"
      :url-base="urlBase"
      :messages="crudMsgs"
      :create-config="createConfig"
      :edit-config="editConfig"
      :headers="tableHeaders"
      :items-process="organizationsProcess"
    >
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon
          :icon="item.is_active ? 'mdi-check' : 'mdi-cancel'"
          :color="item.is_active ? 'success' : 'error'"
        ></v-icon>
      </template>

      <template v-slot:[`item.has_serasa`]="{ item }">
        <v-icon
          :icon="item.has_serasa ? 'mdi-check' : 'mdi-close'"
          :color="item.has_serasa ? 'success' : 'error'"
        ></v-icon>
      </template>

      <template v-slot:createForm>
        <v-text-field
          label="Nome"
          name="name"
          :error-messages="nameError"
          v-model="createFormData.name"
          validate-on="blur"
          :rules="[required]"
          autofocus
          ref="nameInput"
        ></v-text-field>
        <v-text-field
          label="clientID Serasa"
          name="serasa_user"
          :error-messages="serasaUserError"
          v-model="createFormData.serasa_user"
          validation-on="input"
          autofocus
          ref="serasaUserInput"
        ></v-text-field>
        <v-text-field
          label="clientSecret Serasa"
          name="serasa_password"
          :error-messages="serasaPasswordError"
          v-model="createFormData.serasa_password"
          validation-on="input"
          autofocus
          ref="serasaPasswordInput"
        ></v-text-field>
        <v-checkbox
          label="Ativo"
          name="is_active"
          v-model="createFormData.is_active"
          hide-details
          single-line
        ></v-checkbox>
      </template>

      <template v-slot:editForm>
        <v-text-field
          label="Nome"
          name="name"
          :error-messages="nameError"
          v-model="editFormData.name"
          validate-on="blur"
          :rules="[required]"
          autofocus
          ref="nameInput"
        ></v-text-field>
        <v-text-field
          label="clientID Serasa"
          name="serasa_user"
          :error-messages="serasaUserError"
          v-model="editFormData.serasa_user"
          validation-on="input"
          autofocus
          ref="serasaUserInput"
        ></v-text-field>
        <v-text-field
          label="clientSecret Serasa"
          name="serasa_password"
          :error-messages="serasaPasswordError"
          v-model="editFormData.serasa_password"
          validation-on="input"
          autofocus
          ref="serasaPasswordInput"
        ></v-text-field>
        <v-checkbox
          label="Ativo"
          name="is_active"
          v-model="editFormData.is_active"
          hide-details
          single-line
        ></v-checkbox>
        <v-number-input
          :reverse="false"
          controlVariant="default"
          label=""
          :hideInput="false"
          :inset="false"
        ></v-number-input>
      </template>
    </CRUDTable>
  </v-container>
</template>

<script setup lang="ts">
import CRUDTable from "@/components/CRUDTable.vue";
import { localeDateTimeOptions } from "@/consts";
import { checkSerasa, required } from "@/validators";
import { watchOnce } from "@vueuse/core";
import { AxiosError } from "axios";
import { reactive, ref, watch } from "vue";

const defaultCreateFormData = {
  name: "",
  is_active: true,
  serasa_user: "",
  serasa_password: "",
};
const defaultEditFormData = {
  name: "",
  is_active: true,
  serasa_user: "",
  serasa_password: "",
};
const createFormData = reactive({ ...defaultCreateFormData });
const editFormData = reactive({ ...defaultEditFormData });
const urlBase = "/organizations";
const crudMsgs = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
  createSuccess: "Organização criada com sucesso!",
  editSuccess: "Organização editada com sucesso!",
};
const createConfig = {
  btnText: "Nova Organização",
  btnIcon: "mdi-plus",
  formTitle: "Nova Organização",
  formIcon: "mdi-briefcase-plus",
  submitText: "Salvar",
  formDataDefault: defaultCreateFormData,
  handleCreateError: validateFields,
};
const editConfig = {
  btnText: "Editar Organização",
  formTitle: "Editando Organização",
  formIcon: "mdi-briefcase-edit",
  submitText: "Salvar",
  formDataDefault: defaultEditFormData,
  handleEditError: validateFields,
};

const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome",
    key: "name",
  },
  {
    title: "Criação",
    key: "created_at",
  },
  {
    title: "Ativa",
    key: "is_active",
  },
  {
    title: "Credenciais Serasa",
    key: "has_serasa",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const nameInput = ref();
const nameError = ref("");
const existingNameMsg = "Já existe uma organização com esse nome";

const serasaPasswordInput = ref();
const serasaUserError = ref("");
const serasaUserErrorMsg = "O campo 'clientID' é obrigatório";

const serasaUserInput = ref();
const serasaPasswordError = ref("");
const serasaPasswordErrorMsg = "O campo 'clientSecret' é obrigatório";

function watchSerasaFields(formData: any) {
  watch(
    [() => formData.serasa_user, () => formData.serasa_password],
    ([serasa_user, serasa_password]) => {
      validateSerasa(serasa_user, serasa_password);
    }
  );
}

watchSerasaFields(createFormData);
watchSerasaFields(editFormData);

function isPasswordValid() {
  return (
    checkSerasa(createFormData.serasa_password) ||
    checkSerasa(editFormData.serasa_password)
  );
}

function validateSerasa(serasaUser: string, serasaPassword: string) {
  serasaUserError.value = "";
  serasaPasswordError.value = "";

  if (serasaUser && !isPasswordValid()) {
    serasaPasswordError.value = serasaPasswordErrorMsg;
    serasaPasswordInput.value.focus();
  } else if (serasaPassword && !serasaUser) {
    serasaUserError.value = serasaUserErrorMsg;
    serasaUserInput.value.focus();
  }
}

function validateFields(err: AxiosError<any, any>) {
  if (createFormData.serasa_user || createFormData.serasa_password) {
    validateSerasa(createFormData.serasa_user, createFormData.serasa_password);
  }
  if (editFormData.serasa_user || editFormData.serasa_password) {
    validateSerasa(editFormData.serasa_user, editFormData.serasa_password);
  }
  if (err.response?.status === 409) {
    nameError.value = existingNameMsg;
    nameInput.value.focus();
    watchOnce(
      () => createFormData.name || editFormData.name,
      () => (nameError.value = "")
    );
    return true;
  }
  return false;
}

function organizationsProcess(organizations: any[]) {
  return organizations.map((organization) => {
    organization.created_at = new Date(organization.created_at).toLocaleString(
      undefined,
      localeDateTimeOptions
    );
    return organization;
  });
}
</script>

<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <div
          class="d-flex flex-row mb-6 flex-column justify-center align-center"
        >
          <v-form v-model="form" @submit.prevent="changePassword">
            <div class="d-flex flex-column">
              <v-text-field
                :type="passwordPeek ? 'text' : 'password'"
                label="Nova Senha"
                autocomplete="off"
                name="password1"
                v-model="formData.password1"
                validate-on="blur"
                style="min-width: 500px; max-width: 500px"
                :rules="[required, validatePassword]"
              >
                <template v-slot:append-inner>
                  <v-btn
                    variant="plain"
                    density="compact"
                    :icon="passwordPeek ? 'mdi-eye' : 'mdi-eye-off'"
                    tabindex="-1"
                    @click="passwordPeek = !passwordPeek"
                  ></v-btn>
                </template>
              </v-text-field>
              <v-text-field
                :type="passwordPeek ? 'text' : 'password'"
                label="Confirme a Nova Senha"
                autocomplete="off"
                name="password2"
                v-model="formData.password2"
                validate-on="blur"
                style="min-width: 500px; max-width: 500px"
                :rules="[required, validatePassword, checkPasswords]"
              >
                <template v-slot:append-inner>
                  <v-btn
                    variant="plain"
                    density="compact"
                    :icon="passwordPeek ? 'mdi-eye' : 'mdi-eye-off'"
                    tabindex="-1"
                    @click="passwordPeek = !passwordPeek"
                  ></v-btn>
                </template>
              </v-text-field>
              <v-btn
                class="mx-2 px-2"
                color="primary"
                size="x-large"
                type="submit"
              >
                Atualizar Senha
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import axios from "axios";
import { reactive } from "vue";
import { onBeforeMount, ref } from "vue";
import { required, validatePassword } from "@/validators";
import { useRoute, useRouter } from "vue-router";
import { useSnackbar } from "@/store";
import { useUsersStore } from "./store";

const passwordPeek = ref(false);
const route = useRoute();
const router = useRouter();
const store = useUsersStore();

let userId = 0;

onBeforeMount(() => {
  userId = router.currentRoute.value.query.user_id;
  if (store.userMe?.is_admin && userId != store.userMe?.id) {
    router.push({
      path: "home",
      query: {},
    });
  }

  router.push({
    path: route.path,
    query: {},
  });
});

const form = ref(false);
const { showSnackbar } = useSnackbar();

const formData = reactive({
  idUsuario: userId,
  password1: "",
  password2: "",
});

function checkPasswords() {
  return formData.password1 === formData.password2 || "Senhas não correspondem";
}

function changePassword() {
  if (!form.value) return;

  let data = {
    user_id: userId,
    password: formData.password1,
  };
  console.log(data);

  axios
    .post("/password", data)
    .then(() => {
      showSnackbar("Senha alterada com sucesso.", "success");
      console.log(store.userMe?.id);
      console.log(data.user_id);
      if (store.userMe?.id == data.user_id) {
        axios.post("/logout").finally(() => {
          store.logout();
          router.push({ name: "login" });
        });
      } else {
        router.push({ name: "users" });
      }
    })
    .catch(() => {
      showSnackbar("Erro ao alterar senha.", "error");
    });
}
</script>

<template>
  <v-overlay
    id="overlayElement"
    v-model="loadingData"
    scroll-strategy="block"
    location-strategy="static"
    persistent
  >
    <div class="text-center">
      <v-progress-circular
        color="primary"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <p>Pesquisando dados...</p>
  </v-overlay>
  <v-container fluid>
    <div id="result" v-if="report">
      <HeaderSection
        :type="report.type"
        :name="report.name"
        :document="report.document"
      ></HeaderSection>
      <v-row>
        <v-col cols="12" md="10">
          <template v-if="personalData">
            <PersonalSection :data="personalData"></PersonalSection>
          </template>
          <template v-else-if="companyData">
            <CompanySection :data="companyData"></CompanySection>
          </template>
        </v-col>
        <v-col cols="12" md="2">
          <div v-if="report" class="d-flex flex-column align-center">
            <v-btn
              class="mx-2 my-2 w-100 mt-5"
              color="primary"
              size="80"
              @click="$router.push({ name: 'searches' })"
            >
              <div class="text-h4">
                <v-icon left>mdi-close</v-icon>
                <b> Fechar</b>
              </div>
            </v-btn>
            <v-btn
              class="mx-2 my-2 w-100 mt-9"
              color="primary"
              size="80"
              @click="imprimir"
            >
              <div class="text-h4">
                <v-icon left>mdi-cloud-download-outline</v-icon>
                <b> Baixar</b>
              </div>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-n10 align-stretch mb-n8">
        <v-col cols="12" md="9">
          <CompanyFinancialSection
            v-if="companyFinancialData"
            :data="companyFinancialData"
          ></CompanyFinancialSection>
        </v-col>
        <v-col cols="12" md="3">
          <PersonalFinancialSection
            v-if="personalfinancialData"
            :data="personalfinancialData"
          ></PersonalFinancialSection>
        </v-col>
      </v-row>
      <AddressSection v-if="addressData" :data="addressData"></AddressSection>
      <v-row class="mt-n10 mb-n8">
        <v-col cols="12" md="3">
          <PhonesSection v-if="phonesData" :data="phonesData"></PhonesSection>
        </v-col>
        <v-col cols="12" md="3">
          <MailsSection v-if="mailsData" :data="mailsData"></MailsSection>
        </v-col>
        <v-col cols="12" md="6">
          <BrothersSection
            v-if="brothersData"
            :data="brothersData"
          ></BrothersSection>
        </v-col>
      </v-row>
      <NeighborsSection
        v-if="neighborsData"
        :data="neighborsData"
      ></NeighborsSection>
      <RelativesSection
        v-if="relativesData"
        :data="relativesData"
      ></RelativesSection>
      <SintegraSection
        v-if="sintegraData"
        :data="sintegraData"
      ></SintegraSection>
      <PartnersSection
        v-if="partnersData && report.type == 'CNPJ'"
        :data="partnersData"
      ></PartnersSection>
      <DebitsSection
        v-if="debitData"
        :data="debitData"
        :total="debitTotal"
      ></DebitsSection>
      <ProtestsSection
        v-if="protestData"
        :data="protestData"
        :total="protestTotal"
      >
      </ProtestsSection>
      <JudicialSection
        v-if="judicialData && report.judicial"
        :data="judicialData"
      ></JudicialSection>
      <RuralSection
        v-if="ruralData && report.rural"
        :data="ruralData"
        :document="report.document"
      ></RuralSection>
    </div>
  </v-container>
</template>

<style lang="scss">
.right-align {
  text-align: right;
}

#overlayElement .v-overlay__content {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

@media print {
  table {
    page-break-inside: avoid;
  }
}
</style>

<script setup lang="ts">
import axios from "axios";
import html2pdf from "html2pdf.js";
import { onBeforeMount } from "vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useSnackbar } from "@/store";

import AddressSection from "@/modules/search/report_sections/AddressSection.vue";
import BrothersSection from "@/modules/search/report_sections/BrothersSection.vue";
import CompanyFinancialSection from "@/modules/search/report_sections/CompanyFinancialSection.vue";
import CompanySection from "@/modules/search/report_sections/CompanySection.vue";
import DebitsSection from "@/modules/search/report_sections/DebitsSection.vue";
import PersonalFinancialSection from "@/modules/search/report_sections/PersonalFinancialSection.vue";
import HeaderSection from "@/modules/search/report_sections/HeaderSection.vue";
import JudicialSection from "@/modules/search/report_sections/JudicialSection.vue";
import MailsSection from "@/modules/search/report_sections/MailsSection.vue";
import NeighborsSection from "./report_sections/NeighborsSection.vue";
import PartnersSection from "@/modules/search/report_sections/PartnersSection.vue";
import PersonalSection from "@/modules/search/report_sections/PersonalSection.vue";
import PhonesSection from "@/modules/search/report_sections/PhonesSection.vue";
import ProtestsSection from "@/modules/search/report_sections/ProtestsSection.vue";
import RelativesSection from "@/modules/search/report_sections/RelativesSection.vue";
import RuralSection from "@/modules/search/report_sections/RuralSection.vue";
import SintegraSection from "@/modules/search/report_sections/SintegraSection.vue";

const route = useRoute();

const searchId = route.params.search_id as string;

interface Report {
  type: string;
  document: string;
  name: string;
  judicial: boolean;
  rural: boolean;
}

const emptyRecord = {
  type: "",
  document: "",
  name: "",
  judicial: false,
  rural: false,
};

const report = ref<Report>(emptyRecord);
const properties = ref(null);
const personalData = ref(null);
const companyData = ref(null);
const addressData = ref(null);
const phonesData = ref(null);
const mailsData = ref(null);
const brothersData = ref(null);
const neighborsData = ref(null);
const relativesData = ref(null);
const sintegraData = ref(null);
const personalfinancialData = ref(null);
const companyFinancialData = ref(null);
const partnersData = ref(null);
const protestTotal = ref("");
const protestData = ref(null);
const debitTotal = ref("");
const debitData = ref(null);
const judicialData = ref(null);
const ruralData = ref(null);

const { showSnackbar } = useSnackbar();
const searchButtonDisabled = ref(false);
const loadingData = ref(true);

onBeforeMount(() => {
  search();
});

function imprimir() {
  const fileName = report.value.name
    ? `RELATÓRIO ${report.value.name}.pdf`
    : "resultados.pdf";

  html2pdf()
    .from(document.getElementById("result"))
    .set({
      margin: 0.5,
      filename: fileName,
      jsPDF: {
        unit: "cm",
        format: "a4",
        orientation: "landscape",
      },
      pagebreak: { mode: ["avoid-all", "css"] },
      html2canvas: { scale: 2 },
    })
    .save();
}

function search() {
  loadingData.value = true;

  const params = { id_search: searchId };

  axios
    .get("/search", { params })
    .then((res) => {
      showSnackbar("Pesquisa encontrada com sucesso", "success");
      searchButtonDisabled.value = false;
      loadingData.value = false;
      report.value = res.data;
      properties.value = res.data.properties;
      personalData.value = res.data.personal_data;
      companyData.value = res.data.company_data;
      addressData.value = res.data.address;
      phonesData.value = res.data.phones;
      mailsData.value = res.data.mails;
      brothersData.value = res.data.brothers;
      neighborsData.value = res.data.neighbors;
      relativesData.value = res.data.relatives;
      sintegraData.value = res.data.sintegra_data;
      personalfinancialData.value = res.data.personal_financial_data;
      companyFinancialData.value = res.data.company_financial_data;
      partnersData.value = res.data.partners_data;
      debitTotal.value = res.data.debit_total;
      debitData.value = res.data.debit_data;
      protestTotal.value = res.data.protest_total;
      protestData.value = res.data.protest_data;
      judicialData.value = res.data.judicial_data;
      ruralData.value = res.data.rural_data;
    })
    .catch((err) => {
      let errorMessage = "Erro na Pesquisa";
      if (err.response && err.response.data && err.response.data.detail) {
        errorMessage = err.response.data.detail;
      }
      loadingData.value = false;
      showSnackbar(errorMessage, "error");
    });
}
</script>

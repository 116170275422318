<template>
  <div class="my-5">
    <v-card id="company-financial-card">
      <v-card-title class="text-h5 bg-primary font-weight-bold"
        >Classificação Financeira</v-card-title
      >
      <v-row class="my-2 mx-3" align="center" justify="space-between">
        <v-col cols="4" class="text-center">
          <svg viewBox="0 0 64 32" class="gauge">
            <defs>
              <linearGradient
                id="scoreGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stop-color="#FC493F" />
                <stop offset="50%" stop-color="#FFD114" />
                <stop offset="100%" stop-color="#1BB74F" />
              </linearGradient>
            </defs>
            <path
              d="M 4,30 A 28,28 0 0 1 60,30"
              fill="none"
              stroke-width="4"
              stroke="#ccc"
              stroke-linecap="round"
            />
            />
            <path
              d="M 4,30 A 28,28 0 0 1 60,30"
              fill="none"
              stroke-width="4"
              :style="scoreGradientStyle"
              stroke-linecap="round"
            />
            />
            <text
              x="32"
              y="24"
              text-anchor="middle"
              font-size="12"
              font-weight="bold"
            >
              {{ props.data.score }}
            </text>
            <text
              x="32"
              y="30"
              text-anchor="middle"
              font-size="4"
              font-weight="normal"
            >
              /1000
            </text>
          </svg>
          <p>
            <span style="font-weight: bold; font-size: 1.2em">Score</span>
          </p>
        </v-col>
        <v-col cols="4" class="text-center">
          <svg viewBox="0 0 64 32" class="gauge">
            <defs>
              <linearGradient
                id="classificationGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stop-color="#FC493F" />
                <stop offset="50%" stop-color="#FFD114" />
                <stop offset="100%" stop-color="#1BB74F" />
              </linearGradient>
            </defs>
            <path
              d="M 4,30 A 28,28 0 0 1 60,30"
              fill="none"
              stroke-width="4"
              stroke="#ccc"
              stroke-linecap="round"
            />
            />
            <path
              d="M 4,30 A 28,28 0 0 1 60,30"
              fill="none"
              stroke-width="4"
              :style="classificationGradientStyle"
              stroke-linecap="round"
            />
            />
            <text
              x="32"
              y="25"
              text-anchor="middle"
              font-size="12"
              font-weight="bold"
            >
              {{ props.data.classification }}
            </text>
          </svg>
          <p>
            <span style="font-weight: bold; font-size: 1.2em"
              >Classificação</span
            >
          </p>
        </v-col>
        <v-col cols="4" class="text-center">
          <svg viewBox="0 0 64 32" class="gauge">
            <defs>
              <linearGradient
                id="probabilityGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stop-color="#1BB74F" />
                <stop offset="10%" stop-color="#FFD114" />
                <stop offset="100%" stop-color="#FC493F" />
              </linearGradient>
            </defs>
            <path
              d="M 4,30 A 28,28 0 0 1 60,30"
              fill="none"
              stroke-width="4"
              stroke="#ccc"
              stroke-linecap="round"
            />
            />
            <path
              d="M 4,30 A 28,28 0 0 1 60,30"
              fill="none"
              stroke-width="4"
              :style="probabilityGradientStyle"
              stroke-linecap="round"
            />
            />
            <text
              x="32"
              y="25"
              text-anchor="middle"
              font-size="12"
              font-weight="bold"
            >
              {{ parseFloat(props.data.probability) }}%
            </text>
          </svg>
          <p>
            <span style="font-weight: bold; font-size: 1.2em"
              >Prob. de Inadimplência</span
            >
          </p>
        </v-col>
      </v-row>
      <p class="mx-3 pa-2">
        <span style="font-weight: bold">Observação:</span> {{ props.data.text }}
      </p>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface CompanyFinancialData {
  score: string;
  classification: string;
  probability: string;
  text: string;
}

const props = defineProps<{
  data: CompanyFinancialData;
}>();

const calculateDashArrayAndOffset = (
  percentage: number
): { dashArray: string; dashOffset: string } => {
  const circumference = Math.PI * 28;
  const dashOffset = circumference - (circumference * percentage) / 100;
  return {
    dashArray: `${circumference} ${circumference}`,
    dashOffset: `${dashOffset}`,
  };
};

const scoreGradientStyle = computed(() => {
  const scoreNum = parseInt(props.data.score, 10);
  const percentage = (scoreNum / 1000) * 100;
  const { dashArray, dashOffset } = calculateDashArrayAndOffset(percentage);
  return {
    stroke: "url(#scoreGradient)",
    strokeDasharray: dashArray,
    strokeDashoffset: dashOffset,
  };
});

const classificationGradientStyle = computed(() => {
  let percentage = 0;
  switch (props.data.classification.toUpperCase()) {
    case "A":
      percentage = 100;
      break;
    case "B":
      percentage = 80;
      break;
    case "C":
      percentage = 50;
      break;
    case "D":
      percentage = 30;
      break;
    case "E":
      percentage = 20;
      break;
    case "F":
      percentage = 5;
      break;
    default:
      percentage = 0;
  }
  const { dashArray, dashOffset } = calculateDashArrayAndOffset(percentage);
  return {
    stroke: "url(#classificationGradient)",
    strokeDasharray: dashArray,
    strokeDashoffset: dashOffset,
  };
});

const probabilityGradientStyle = computed(() => {
  const prob = parseFloat(props.data.probability);
  const invertedPercentage = prob;
  const { dashArray, dashOffset } =
    calculateDashArrayAndOffset(invertedPercentage);
  return {
    stroke: "url(#probabilityGradient)",
    strokeDasharray: dashArray,
    strokeDashoffset: dashOffset,
  };
});
</script>

<style scoped>
.gauge {
  width: 200px;
  height: auto;
}
</style>

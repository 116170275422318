import { RouteRecordRaw } from "vue-router";

import AdminHomeView from "@/modules/admin/AdminHomeView.vue";
import ChangePasswordView from "@/modules/users/ChangePasswordView.vue";
import CompaniesView from "@/modules/users/CompaniesView.vue";
import ImportMapBiomasCSVView from "@/modules/search/ImportMapBiomasCSVView.vue";
import ImportMapBiomasImageView from "@/modules/search/ImportMapBiomasImageView.vue";
import ImportSIGEFAreaView from "@/modules/search/ImportSIGEFAreaView.vue";
import LoginView from "@/modules/users/LoginView.vue";
import MapBiomasView from "@/modules/mapbiomas/MapBiomasView.vue";
import MetabaseView from "@/modules/metabase/MetabaseView.vue";
import NavbarView from "@/components/NavbarView.vue";
import OrganizationsView from "@/modules/users/OrganizationsView.vue";
import SearchView from "@/modules/search/SearchView.vue";
import SearchesView from "@/modules/search/SearchesView.vue";
import SearchReportView from "@/modules/search/SearchReportView.vue";
import UsersView from "@/modules/users/UsersView.vue";
import SharedLinkLoginView from "@/modules/users/SharedLinkLoginView.vue";
import PublicSearchReportView from "@/modules/search/PublicSearchReportView.vue";
import PublicNavbarView from "@/components/PublicNavbarView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "home",
    component: NavbarView,
    redirect: "search",
    children: [
      {
        path: "mapbiomas",
        name: "mapbiomas",
        component: MapBiomasView,
        meta: {
          title: "MapBiomas | FarmRISK",
        },
      },
      {
        path: "import_csv_mapbiomas",
        name: "import_csv_mapbiomas",
        component: ImportMapBiomasCSVView,
        meta: {
          title: "Importação de CSV MapBiomas | FarmRISK",
        },
      },
      {
        path: "import_mapbiomas",
        name: "import_mapbiomas",
        component: ImportMapBiomasImageView,
        meta: {
          title: "Importação de MapBiomas | FarmRISK",
        },
      },
      {
        path: "import_sigef",
        name: "import_sigef",
        component: ImportSIGEFAreaView,
        meta: {
          title: "Importação de SIGEF | FarmRISK",
        },
      },
      {
        path: "search",
        name: "search",
        component: SearchView,
        meta: {
          title: "Pesquisa | FarmRISK",
        },
      },
      {
        path: "search/:search_id(\\d+)",
        name: "search_report",
        component: SearchReportView,
        meta: {
          title: "Relatório | FarmRISK",
        },
      },
      {
        path: "searches",
        name: "searches",
        component: SearchesView,
        meta: {
          title: "Pesquisas | FarmRISK",
        },
      },
      {
        path: "metabase",
        name: "metabase",
        component: MetabaseView,
        meta: {
          title: "Dashboard | FarmRISK",
        },
      },
      {
        path: "organizations",
        name: "organizations",
        component: OrganizationsView,
        meta: {
          title: "Organizações | FarmRISK",
        },
      },
      {
        path: "companies",
        name: "companies",
        component: CompaniesView,
        meta: {
          title: "Empresas | FarmRISK",
        },
      },
      {
        path: "users",
        name: "users",
        component: UsersView,
        meta: {
          title: "Usuários | FarmRISK",
        },
      },
      {
        path: "change_password",
        name: "change_password",
        component: ChangePasswordView,
        meta: {
          title: "Alterar Senha | FarmRISK",
        },
      },
    ],
  },
  {
    path: "/public/search/:search_id",
    name: "public_search_report",
    component: SharedLinkLoginView,
    meta: {
      title: "Relatório Público | FarmRISK",
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminHomeView,
    meta: {
      title: "Admin | FarmRISK",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "FarmRISK - Login",
    },
    props: (route) => ({ ...route.query, ...route.params }),
  },
];

export default routes;

<template>
  <v-container fluid class="bg-white">
    <h1>MapBiomas</h1>
    <v-btn
      text="Importar MapBiomas"
      variant="tonal"
      color="primary"
      prepend-icon="mdi-plus"
      @click="importMapBiomas"
    ></v-btn>
    <SimpleTable
      :url-base="urlBase"
      :messages="dataMessages"
      :headers="tableHeaders"
      :items-process="mapBiomasProcess"
      @create-opened="loadAll"
      @edit-opened="loadAll"
    >
    </SimpleTable>
  </v-container>
</template>

<script setup lang="ts">
import { localeDateTimeOptions } from "@/consts";
import SimpleTable from "@/components/SimpleTable.vue";
import { useLoadMapBiomas } from "@/composables/load-mapbiomas";
import { useRouter } from "vue-router";

const router = useRouter();
const urlBase = "/mapbiomas";
const dataMessages = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
};

const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Ano",
    key: "year",
  },
  {
    title: "Id Imagem",
    key: "image",
  },
  {
    title: "Usuário",
    key: "created_by.full_name",
  },
  {
    title: "Data",
    key: "created_at",
  },
];

const { mapBiomas, loadingMapBiomas, load: loadMapBiomas } = useLoadMapBiomas();

function importMapBiomas() {
  router.push({ name: "import_mapbiomas" });
}

function mapBiomasProcess(mapBiomas: any[]) {
  return mapBiomas.map((mapBiomas) => {
    mapBiomas.created_at = new Date(mapBiomas.created_at).toLocaleString(
      undefined,
      localeDateTimeOptions
    );
    return mapBiomas;
  });
}

async function loadAll() {
  await Promise.all([loadMapBiomas()]);
}
</script>

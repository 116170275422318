<template>
  <v-app-bar color="navbar" class="justify-center">
    <v-app-bar-title>
      <v-img src="/static/assets/logo2.png" width="300"></v-img>
    </v-app-bar-title>
  </v-app-bar>
  <v-main style="height: 100%" class="bg-grey-lighten-3">
    <router-view />
  </v-main>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.v-application {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-app-bar::v-deep .v-toolbar__content {
  max-width: 1280px;
  margin: auto;
}
</style>

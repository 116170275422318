<template>
  <div class="my-5">
    <v-card>
      <v-card-title class="text-h5 bg-primary font-weight-bold">
        Sintegra
      </v-card-title>
      <div class="my-2 mx-3" v-if="paginatedData.length > 0">
        <v-table>
          <thead>
            <tr>
              <th v-for="header in headers" :key="header">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in paginatedData" :key="rowIndex">
              <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                {{ cell }}
              </td>
            </tr>
          </tbody>
        </v-table>
        <div class="mt-2 d-flex align-center justify-end">
          <v-btn text color="primary" @click="prevPage" :disabled="page === 1">
            Anterior
          </v-btn>
          <span class="mx-2"> Página {{ page }} de {{ totalPages }} </span>
          <v-btn
            text
            color="primary"
            @click="nextPage"
            :disabled="page === totalPages"
          >
            Próximo
          </v-btn>
        </div>
      </div>
      <div class="my-2 mx-3" v-else>
        Sem registro(s) no Sintegra encontrados!
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
const props = defineProps<{
  data: Array<object>;
}>();
const headers = [
  "Propriedade",
  "IE",
  "Situação",
  "Localidade",
  "Cidade/UF",
  "Últ. Situação",
  "CNAE",
];
const itemsPerPage = 5;
const page = ref(1);
const totalPages = computed(() => Math.ceil(props.data.length / itemsPerPage));
const paginatedData = computed(() => {
  const startIndex = (page.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return props.data.slice(startIndex, endIndex);
});
function nextPage() {
  if (page.value < totalPages.value) page.value++;
}
function prevPage() {
  if (page.value > 1) page.value--;
}
</script>

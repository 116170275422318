<template>
  <div>
    <v-card class="mt-5">
      <canvas ref="chart"></canvas>
    </v-card>
  </div>
</template>
<script setup lang="ts">
import Chart from "chart.js/auto";
import { onMounted, ref } from "vue";

interface CaptionData {
  label: string;
  data: Array<number>;
  backgroundColor: string;
}

interface CARdata {
  id: number;
  category: string;
  cod_imovel: string;
  situation: string;
  total_area: number;
  type: string;
  condition: string;
  mod_fiscal: string;
}

interface CARCaptionData {
  labels: Array<string>;
  datasets: Array<CaptionData>;
  data: Array<CARdata>;
  geojson: object;
  sigef_area_geojson: object;
}

const props = defineProps<{
  caption: CARCaptionData;
}>();

const chart = ref(null);
onMounted(() => {
  const ctx = chart.value.getContext("2d");

  const allZero = props.caption.datasets.every((dataset) =>
    dataset.data.every((value) => value === 0)
  );

  if (allZero) {
    return;
  }

  const filteredLabels = props.caption.labels.filter((label, index) => {
    return props.caption.datasets.some((dataset) => dataset.data[index] > 0);
  });

  const filteredDatasets = props.caption.datasets
    .filter((dataset) => dataset.data.some((value) => value > 0))
    .map((dataset) => ({
      ...dataset,
      data: dataset.data.filter((value, index) =>
        props.caption.datasets.some((ds) => ds.data[index] > 0)
      ),
    }));

  new Chart(ctx, {
    type: "bar",
    data: {
      labels: filteredLabels,
      datasets: filteredDatasets,
    },
    options: {
      responsive: true,
      indexAxis: "y",
      scales: {
        x: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            padding: 40,
            autoSkip: true,
            maxTicksLimit: 10,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
        y: {
          stacked: true,
          ticks: {
            padding: 40,
            autoSkip: true,
            maxTicksLimit: 10,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      },
      barThickness: 45,
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      },
    },
  });
});
</script>

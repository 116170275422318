<template>
  <div class="my-5">
    <v-card>
      <v-card-title class="text-h5 bg-primary font-weight-bold">
        Sócios
      </v-card-title>
      <div class="mx-3 my-2">
        <div v-if="paginatedData.length > 0">
          <table>
            <thead>
              <tr>
                <th v-for="header in headers" :key="header">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in paginatedData" :key="index">
                <td
                  v-for="(cell, cellIndex) in Object.values(row)"
                  :key="cellIndex"
                >
                  {{ cell }}
                </td>
                <td>
                  <v-icon
                    icon="mdi-magnify"
                    class="me-2"
                    color="primary"
                    @click="newSearch(row.document)"
                    title="Nova Consulta"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-2 d-flex align-center justify-end">
            <v-btn
              text
              color="primary"
              @click="prevPage"
              :disabled="page === 1"
            >
              Anterior
            </v-btn>
            <span class="mx-2"> Página {{ page }} de {{ totalPages }} </span>
            <v-btn
              text
              color="primary"
              @click="nextPage"
              :disabled="page === totalPages"
            >
              Próximo
            </v-btn>
          </div>
        </div>
        <div v-else>
          <p>Sem informações de sócios disponíveis!</p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

interface PartnerData {
  name: string;
  document: string;
  role: string;
  start_date: string;
}

const props = defineProps<{
  data: Array<PartnerData>;
}>();

const headers = [
  "Documento",
  "Nome/Razão Social",
  "Cargo/Quotas",
  "Data Inclusão",
  "Ações",
];

const router = useRouter();

function newSearch(document: string) {
  router.push({ name: "search", query: { document } });
}

const itemsPerPage = 5;
const page = ref(1);
const totalPages = computed(() => Math.ceil(props.data.length / itemsPerPage));
const paginatedData = computed(() => {
  const startIndex = (page.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return props.data.slice(startIndex, endIndex);
});
function nextPage() {
  if (page.value < totalPages.value) page.value++;
}
function prevPage() {
  if (page.value > 1) page.value--;
}
</script>

<template>
  <div class="px-4 py-2 d-flex justify-space-between">
    <v-combobox
      append-inner-icon="mdi-magnify"
      label="Pesquisar"
      name="search"
      density="compact"
      v-model="search"
      hide-details
      single-line
      multiple
      chips
      autocomplete="off"
      style="max-width: 400px"
    ></v-combobox>
    <v-btn
      v-if="createConfig"
      :text="createConfig.btnText"
      variant="tonal"
      color="primary"
      :prepend-icon="createConfig.btnIcon"
      @click="initCreate"
    ></v-btn>
  </div>
  <v-data-table
    class="crud-table"
    :items="items"
    item-value="id"
    :headers="headers"
    :loading="loading"
    :search="searchQuery"
    :custom-filter="customFilter"
  >
    <template v-for="slot in Object.keys(slots)" v-slot:[slot]="{ item }">
      <slot :name="slot" :item="item"></slot>
    </template>
    <template v-if="editConfig" v-slot:[`item.actions`]="{ item }">
      <div class="d-flex align-center" style="width: 100%">
        <div class="flex-grow-1 d-flex justify-center">
          <v-switch
            :model-value="item.is_active"
            density="compact"
            class="me-2"
            hide-details
            :color="item.is_active ? 'success' : ''"
            :class="{ 'inactive-switch': !item.is_active }"
            @click.stop.prevent="onSwitchClick(item)"
          ></v-switch>
        </div>
        <div class="d-flex align-center">
          <v-icon
            icon="mdi-lock"
            class="me-2"
            :data-tippy-content="editConfig.btnText"
            color="primary"
            @click="changePassword(item)"
          ></v-icon>
          <v-icon
            icon="mdi-pencil"
            class="me-2"
            :data-tippy-content="editConfig.btnText"
            color="primary"
            @click="initEdit(item)"
          ></v-icon>
        </div>
      </div>
    </template>
  </v-data-table>

  <FormDialog
    v-if="createConfig"
    :title="createConfig.formTitle"
    :titleIcon="createConfig.formIcon"
    :submitText="createConfig.submitText"
    :open="openCreateForm"
    :form-error="formError"
    :submit="submitCreate"
    @close="closeCreate"
  >
    <slot name="createForm"></slot>
  </FormDialog>

  <FormDialog
    v-if="editConfig"
    :title="editConfig.formTitle"
    :titleIcon="editConfig.formIcon"
    :submitText="editConfig.submitText"
    :open="openEditForm"
    :form-error="formError"
    :submit="submitEdit"
    @close="closeEdit"
  >
    <slot name="editForm"></slot>
  </FormDialog>

  <v-dialog v-model="showDeactivateStep1" max-width="600px">
    <v-card>
      <v-card-title class="blue-title text-h6">
        <v-row class="pa-5 justify-end">
          <div class="d-flex align-center justify-start me-auto">
            <v-icon class="me-2">mdi-alert-outline</v-icon>
            Atenção
          </div>
          <v-btn size="small" icon color="grey-lighten-4" @click="cancelStep1">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text>
        <p class="text-body-1">
          Ao desativar este usuário, ele perderá o acesso ao sistema
          imediatamente. Essa ação pode ser revertida posteriormente, se
          necessário.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancelStep1">Cancelar</v-btn>
        <v-btn color="primary" text @click="goToStep2">Avançar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showDeactivateStep2" max-width="600px">
    <v-card>
      <v-card-title class="blue-title text-h6">
        <v-row class="pa-5 justify-end">
          <div class="d-flex align-center justify-start me-auto">
            <v-icon class="me-2">mdi-alert-outline</v-icon>
            Cuidado
          </div>
          <v-btn size="small" icon color="grey-lighten-4" @click="cancelStep2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text>
        <p class="text-body-1">
          Você tem certeza que deseja desativar este usuário? Ele perderá o
          acesso ao sistema. Para confirmar, digite <strong>SIM</strong> no
          campo abaixo.
        </p>
        <v-text-field
          v-model="confirmText"
          label="Confirmação"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancelStep2">Cancelar</v-btn>
        <v-btn color="primary" text @click="confirmDeactivation">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useSnackbar } from "@/store";
import { watchOnce } from "@vueuse/core";
import axios, { AxiosError } from "axios";
import tippy from "tippy.js";
import { computed, onBeforeMount, ref, useSlots } from "vue";
import FormDialog from "./FormDialog.vue";
import { useRouter } from "vue-router";

export interface Messages {
  unexpectedError: string;
  createSuccess: string;
  editSuccess: string;
}

export interface CreateConfig {
  btnText: string;
  btnIcon: string;
  formTitle: string;
  formIcon: string;
  submitText: string;
  formDataDefault: any;
  handleCreateError?: (err: AxiosError<any, any>) => boolean;
}

export interface EditConfig {
  btnText: string;
  formTitle: string;
  formIcon: string;
  submitText: string;
  formDataDefault: any;
  handleEditError?: (err: AxiosError<any, any>) => boolean;
}

interface Props {
  messages: Messages;
  urlBase: string;
  headers: any[];
  createConfig?: CreateConfig;
  editConfig?: EditConfig;
  itemsProcess?: (items: any[]) => any[];
  createProcess?: (data: any) => any;
  editProcess?: (data: any) => any;
}
const props = withDefaults(defineProps<Props>(), {});

// eslint-disable-next-line
const formError = defineModel("formError", {
  required: false,
  type: String,
  default: "",
});
// eslint-disable-next-line
const createFormData = defineModel("createFormData", {
  required: true,
  type: Object,
});
// eslint-disable-next-line
const editFormData = defineModel("editFormData", {
  required: true,
  type: Object,
});

const { showSnackbar } = useSnackbar();
const router = useRouter();

const emit = defineEmits<{
  (e: "createOpened"): void;
  (e: "editOpened", item: any): void;
}>();

const slots = useSlots();
const items = ref<any[]>([]);
const search = ref<string[]>([]);
const loading = ref(false);
const searchQuery = computed(() => {
  return search.value.join(",");
});

const showDeactivateStep1 = ref(false);
const showDeactivateStep2 = ref(false);
const confirmText = ref("");
const pendingItem = ref<any>(null);
let oldValue = false;

onBeforeMount(() => {
  getItems();
});

function customFilter(
  value: string | number,
  query: string
): boolean | number | [number, number] | [number, number][] {
  if (typeof value === "number") value = value.toString();

  const queries = query
    .trim()
    .split(",")
    .filter((q) => q)
    .map((q) => q.trim().toLocaleLowerCase());

  return (
    value != null &&
    query != null &&
    typeof value === "string" &&
    queries.every((q) =>
      value
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(q)
    )
  );
}

async function getItems() {
  try {
    loading.value = true;

    const res = await axios.get(props.urlBase);
    items.value = props.itemsProcess ? props.itemsProcess(res.data) : res.data;
    if (res.data.length != items.value.length) {
      throw new Error(
        "CRUDTable: 'itemsProcess' returned different number of items from response"
      );
    }
  } catch (err) {
    showSnackbar(props.messages.unexpectedError, "error");
    console.error(err);
  } finally {
    tippy("[data-tippy-content]", { arrow: false });
    loading.value = false;
  }
}

const openCreateForm = ref(false);

function initCreate() {
  openCreateForm.value = true;
  emit("createOpened");
}

async function submitCreate() {
  try {
    await axios.post(props.urlBase, createFormData.value);
    closeCreate();
    showSnackbar(props.messages.createSuccess, "success");
    getItems();
  } catch (err) {
    handleSubmitError(err, props.createConfig?.handleCreateError);
  }
}

function closeCreate() {
  formError.value = "";
  openCreateForm.value = false;
  resetCreateForm();
}

function resetCreateForm() {
  Object.assign(createFormData.value, props.createConfig?.formDataDefault);
}

const openEditForm = ref(false);
const editId = ref();

function changePassword(item: any) {
  router.push({
    name: "change_password",
    query: { user_id: item.id },
  });
}

function initEdit(item: any) {
  for (const key in editFormData.value) {
    editFormData.value[key] = item[key];
  }
  openEditForm.value = true;
  editId.value = item.id;
  emit("editOpened", item);
}

async function submitEdit() {
  try {
    await axios.patch(`${props.urlBase}/${editId.value}`, editFormData.value);
    closeEdit();
    showSnackbar(props.messages.editSuccess, "success");
    getItems();
  } catch (err) {
    handleSubmitError(err, props.editConfig?.handleEditError);
  }
}

function handleSubmitError(
  err: any,
  handler: ((err: AxiosError<any, any>) => boolean) | undefined
) {
  if (axios.isAxiosError(err)) {
    const errorHandled = handler && handler(err);
    if (errorHandled) return;
  }
  formError.value = props.messages.unexpectedError;
  console.error(err);
  watchOnce(
    () => editFormData,
    () => (formError.value = "")
  );
}

function closeEdit() {
  formError.value = "";
  openEditForm.value = false;
  resetEditForm();
}

function resetEditForm() {
  editId.value = undefined;
  Object.assign(editFormData.value, props.editConfig?.formDataDefault);
}

function initToggle(item: any) {
  if (!item.is_active) {
    toggleActive(item);
    return;
  }
  pendingItem.value = item;
  oldValue = item.is_active;
  showDeactivateStep1.value = true;
}

function cancelStep2() {
  pendingItem.value = null;
  showDeactivateStep2.value = false;
}

function cancelStep1() {
  pendingItem.value = null;
  showDeactivateStep1.value = false;
}

function goToStep2() {
  showDeactivateStep1.value = false;
  showDeactivateStep2.value = true;
}

function confirmDeactivation() {
  if (confirmText.value.trim().toUpperCase() === "SIM") {
    showDeactivateStep2.value = false;
    toggleActive(pendingItem.value);
  } else {
    revertSwitch();
    showSnackbar("Texto de confirmação incorreto.", "error");
  }
  confirmText.value = "";
}

function onSwitchClick(item: any) {
  if (item.is_active) {
    pendingItem.value = item;
    showDeactivateStep1.value = true;
  } else {
    setUserActive(item, true);
  }
}

function setUserActive(item: any, newValue: boolean) {
  axios
    .delete(`/users/${item.id}`, { is_active: newValue })
    .then(() => {
      item.is_active = newValue;
      showSnackbar("Status atualizado com sucesso!", "success");
    })
    .catch((err) => {
      showSnackbar("Não foi possível atualizar o status do usuário.", "error");
      console.error(err);
    });
}

function toggleActive(item: any) {
  const oldVal = item.is_active;
  item.is_active = !oldVal;

  axios
    .delete(`/users/${item.id}`, { is_active: item.is_active })
    .then(() => {
      showSnackbar("Status atualizado com sucesso!", "success");
    })
    .catch((err) => {
      item.is_active = oldVal;
      showSnackbar("Não foi possível atualizar o status do usuário.", "error");
      console.error(err);
    })
    .finally(() => {
      pendingItem.value = null;
    });
}

function revertSwitch() {
  if (pendingItem.value) {
    pendingItem.value.is_active = oldValue;
    pendingItem.value = null;
  }
  confirmText.value = "";
}
</script>

<style scoped lang="scss">
.crud-table::v-deep tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.inactive-switch ::v-deep .v-switch__track {
  background-color: red !important;
}
.inactive-switch ::v-deep .v-switch__thumb {
  background-color: red !important;
}
.blue-title {
  background-color: #1976d2;
  color: #ffffff;
  padding: 10px;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
}
</style>

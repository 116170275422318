<template>
  <div>
    <div v-if="reportData">
      <PublicNavbarView />
      <v-main class="bg-grey-lighten-3" style="height: 100%">
        <PublicSearchReportView :report-data="reportData" />
      </v-main>
    </div>
    <div v-else>
      <div class="d-flex w-100 h-100 align-center justify-center">
        <v-card class="w-25">
          <v-card-item>
            <v-img src="/static/assets/logo_vert.png" class="mx-auto"></v-img>
          </v-card-item>
          <v-card-text>
            <v-form v-model="form" @submit.prevent="submitForm">
              <v-text-field
                :type="passwordPeek ? 'text' : 'password'"
                label="Senha"
                name="password"
                v-model="password"
                validate-on="blur"
                :rules="[required]"
              >
                <template v-slot:append-inner>
                  <v-btn
                    variant="plain"
                    density="compact"
                    :icon="passwordPeek ? 'mdi-eye' : 'mdi-eye-off'"
                    @click="passwordPeek = !passwordPeek"
                  ></v-btn>
                </template>
              </v-text-field>
              <span class="text-error">{{ errorMessage }}</span>
              <v-btn
                type="submit"
                size="large"
                block
                color="primary"
                append-icon="mdi-login"
                class="mt-2"
                :loading="loading"
              >
                Acessar
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";
import { useRoute } from "vue-router";
import PublicSearchReportView from "@/modules/search/PublicSearchReportView.vue";
import PublicNavbarView from "@/components/PublicNavbarView.vue";

const route = useRoute();

const reportData = ref(null);
const passwordPeek = ref(false);
const form = ref(false);
const password = ref("");
const loading = ref(false);
const errorMessage = ref("");
const required = (value: string) => !!value || "Senha é obrigatória.";

async function submitForm() {
  if (!form.value) return;

  const uuid = route.params.search_id;
  if (!uuid) {
    errorMessage.value = "Link inválido. Tente novamente.";
    return;
  }

  loading.value = true;
  errorMessage.value = "";

  try {
    const response = await axios.get(`/public/search`, {
      params: {
        uuid_search: uuid,
        password: password.value,
      },
    });

    reportData.value = response.data;
  } catch (err) {
    if (err.response?.status === 401) {
      errorMessage.value = "Senha incorreta.";
    } else {
      errorMessage.value =
        "Erro inesperado. Tente novamente ou entre em contato com o suporte.";
    }
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped>
.v-card {
  margin-top: 20vh;
}
.text-error {
  color: red;
  font-size: 0.9rem;
}
.d-flex {
  height: 100vh;
}
</style>

<template>
  <v-container fluid class="ma-4">
    <v-row class="pa-2" dense>
      <v-col cols="12" md="6" class="pa-2">
        <v-card class="pa-4">
          <div class="chart-header">
            <span class="card-title centered-title">
              Bilhetagem do mês atual por organização
            </span>
            <div class="current-month">{{ currentMonthName }}</div>
            <v-btn-toggle
              v-model="activeFiltersOrgMonth"
              multiple
              class="filter-toggle"
            >
              <v-btn value="all">Tudo</v-btn>
              <v-btn value="judicial">Com Processos Judiciais</v-btn>
              <v-btn v-if="hasSerasaDataOrgMonth" value="serasa"
                >Com Serasa</v-btn
              >
              <v-btn value="rural">Com Imóveis Rurais</v-btn>
            </v-btn-toggle>
          </div>
          <div class="canvas-container">
            <canvas ref="chartOrgMonth"></canvas>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="pa-2">
        <v-card class="pa-4">
          <div class="chart-header">
            <span class="card-title centered-title">
              Bilhetagem do mês atual por empresa
            </span>
            <div class="current-month">{{ currentMonthName }}</div>
            <v-btn-toggle
              v-model="activeFiltersCompanyMonth"
              multiple
              class="filter-toggle"
            >
              <v-btn value="all">Tudo</v-btn>
              <v-btn value="judicial">Com Processos Judiciais</v-btn>
              <v-btn v-if="hasSerasaDataCompanyMonth" value="serasa"
                >Com Serasa</v-btn
              >
              <v-btn value="rural">Com Imóveis Rurais</v-btn>
            </v-btn-toggle>
          </div>
          <div class="canvas-container">
            <canvas ref="chartCompanyMonth"></canvas>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" class="pa-2">
        <v-card class="pa-4">
          <div class="chart-header">
            <span class="card-title centered-title">
              Histórico de bilhetagem por organização
            </span>
            <div class="current-month">Últimos 12 meses</div>
            <v-btn-toggle
              v-model="activeFiltersOrgHistory"
              multiple
              class="filter-toggle"
            >
              <v-btn value="all">Tudo</v-btn>
              <v-btn value="judicial">Com Processos Judiciais</v-btn>
              <v-btn v-if="hasSerasaDataOrgHistory" value="serasa"
                >Com Serasa</v-btn
              >
              <v-btn value="rural">Com Imóveis Rurais</v-btn>
            </v-btn-toggle>
          </div>
          <div class="canvas-container">
            <canvas ref="chartOrgHistory"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import Chart from "chart.js/auto";

Chart.defaults.color = "#000";
Chart.defaults.font.size = 14;
Chart.defaults.font.family = "sans-serif";
Chart.defaults.font.weight = "normal";

function getCurrentMonthName() {
  return new Date().toLocaleString("pt-BR", { month: "long", year: "numeric" });
}
const currentMonthName = ref(getCurrentMonthName());

function getColorForLabel(label: string): string {
  const distinctHues = [20, 60, 100, 140, 180, 220, 260, 300, 340];
  let hash = 0;
  for (let i = 0; i < label.length; i++) {
    hash = label.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % distinctHues.length;
  const hue = distinctHues[index];
  return `hsl(${hue}, 70%, 50%)`;
}

const activeFiltersOrgMonth = ref<string[]>(["all"]);
const activeFiltersCompanyMonth = ref<string[]>(["all"]);
const activeFiltersOrgHistory = ref<string[]>(["all"]);

const chartOrgMonth = ref<HTMLCanvasElement | null>(null);
let chartOrgMonthInstance: Chart | null = null;

const chartCompanyMonth = ref<HTMLCanvasElement | null>(null);
let chartCompanyMonthInstance: Chart | null = null;

const chartOrgHistory = ref<HTMLCanvasElement | null>(null);
let chartOrgHistoryInstance: Chart | null = null;

const billingsOrgMonthData = ref<Array<any>>([]);
const billingsCompanyMonthData = ref<Array<any>>([]);
const billingsOrgHistoryData = ref<Array<any>>([]);

async function fetchData(url: string) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Erro ao buscar dados de ${url}`);
  }
  return response.json();
}

function applyFilters(billings: Array<any>, active: string[]): Array<any> {
  if (active.includes("all")) return billings;
  if (active.length === 0) {
    return billings.filter((b) => !b.judicial && !b.serasa && !b.rural);
  }
  return billings.filter(
    (b) =>
      (active.includes("judicial")
        ? b.judicial === true
        : b.judicial === false) &&
      (active.includes("serasa") ? b.serasa === true : b.serasa === false) &&
      (active.includes("rural") ? b.rural === true : b.rural === false)
  );
}

function groupByDayAndOrganization(billings: Array<any>) {
  const map = new Map<number, Record<string, number>>();
  const orgNames = new Set<string>();

  for (const b of billings) {
    const date = new Date(b.search_created_at);
    const day = date.getDate();
    const org = b.organization_name || "Sem Org?";

    if (!map.has(day)) {
      map.set(day, {});
    }
    const dayMap = map.get(day)!;
    dayMap[org] = (dayMap[org] || 0) + 1;
    orgNames.add(org);
  }

  const sortedDays = Array.from(map.keys()).sort((a, b) => a - b);
  const orgArray = Array.from(orgNames);

  const datasets = orgArray.map((org) => ({
    label: org,
    data: sortedDays.map((day) => map.get(day)![org] || 0),
    backgroundColor: getColorForLabel(org),
  }));

  return {
    labels: sortedDays.map((day) => day.toString()),
    datasets,
  };
}

function groupByDayAndCompany(billings: Array<any>) {
  const map = new Map<number, Record<string, number>>();
  const companies = new Set<string>();

  for (const b of billings) {
    const date = new Date(b.search_created_at);
    const day = date.getDate();
    const company = b.company_name || "Sem Empresa?";

    if (!map.has(day)) {
      map.set(day, {});
    }
    const dayMap = map.get(day)!;
    dayMap[company] = (dayMap[company] || 0) + 1;
    companies.add(company);
  }

  const sortedDays = Array.from(map.keys()).sort((a, b) => a - b);
  const companyArray = Array.from(companies);

  const datasets = companyArray.map((companyName) => ({
    label: companyName,
    data: sortedDays.map((day) => map.get(day)![companyName] || 0),
    backgroundColor: getColorForLabel(companyName),
  }));

  return {
    labels: sortedDays.map((day) => day.toString()),
    datasets,
  };
}

function groupByMonthYearAndOrganization(billings: Array<any>) {
  const map = new Map<string, Record<string, number>>();
  const orgNames = new Set<string>();

  for (const b of billings) {
    const date = new Date(b.search_created_at);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const org = b.organization_name || "Sem Org?";
    const key = `${String(month).padStart(2, "0")}/${year}`;

    if (!map.has(key)) {
      map.set(key, {});
    }
    const keyMap = map.get(key)!;
    keyMap[org] = (keyMap[org] || 0) + 1;
    orgNames.add(org);
  }

  let sortedKeys = Array.from(map.keys());
  sortedKeys.sort((a, b) => {
    const [mA, yA] = a.split("/").map(Number);
    const [mB, yB] = b.split("/").map(Number);
    return yA - yB || mA - mB;
  });

  const orgArray = Array.from(orgNames);

  const datasets = orgArray.map((org) => ({
    label: org,
    data: sortedKeys.map((key) => map.get(key)![org] || 0),
    backgroundColor: getColorForLabel(org),
  }));

  return {
    labels: sortedKeys,
    datasets,
  };
}

async function loadDataAndCreateCharts() {
  try {
    billingsOrgMonthData.value = await fetchData(
      "/billings-organization-by-month"
    );
    billingsCompanyMonthData.value = await fetchData(
      "/billings-companies-by-month"
    );
    billingsOrgHistoryData.value = await fetchData(
      "/billings-history-by-organization"
    );

    updateChartOrgMonth();
    updateChartCompanyMonth();
    updateChartOrgHistory();
  } catch (err) {
    console.error("Erro ao carregar dados:", err);
  }
}

function updateChartOrgMonth() {
  const filtered = applyFilters(
    billingsOrgMonthData.value,
    activeFiltersOrgMonth.value
  );
  const { labels, datasets } = groupByDayAndOrganization(filtered);

  if (chartOrgMonthInstance) {
    chartOrgMonthInstance.data.labels = labels;
    chartOrgMonthInstance.data.datasets = datasets;
    chartOrgMonthInstance.update();
  } else if (chartOrgMonth.value) {
    chartOrgMonthInstance = new Chart(chartOrgMonth.value.getContext("2d")!, {
      type: "bar",
      data: { labels, datasets },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: { display: false },
          tooltip: {
            callbacks: {
              title: (context: any) => `dia ${context[0].label}`,
              label: (context: any) => {
                const label = context.dataset.label || "";
                const value = context.parsed.y;
                const text = value === 1 ? "consulta" : "consultas";
                return `${label}: ${value} ${text}`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Dia",
              font: { size: 18, weight: "bold" },
            },
            ticks: { font: { size: 14, weight: "bold" } },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Quantidade",
              font: { size: 18, weight: "bold" },
            },
            ticks: { stepSize: 1, font: { size: 14, weight: "bold" } },
          },
        },
      },
    });
  }
}

function updateChartCompanyMonth() {
  const filtered = applyFilters(
    billingsCompanyMonthData.value,
    activeFiltersCompanyMonth.value
  );
  const { labels, datasets } = groupByDayAndCompany(filtered);

  if (chartCompanyMonthInstance) {
    chartCompanyMonthInstance.data.labels = labels;
    chartCompanyMonthInstance.data.datasets = datasets;
    chartCompanyMonthInstance.update();
  } else if (chartCompanyMonth.value) {
    chartCompanyMonthInstance = new Chart(
      chartCompanyMonth.value.getContext("2d")!,
      {
        type: "bar",
        data: { labels, datasets },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: { display: false },
            tooltip: {
              callbacks: {
                title: (context: any) => `dia ${context[0].label}`,
                label: (context: any) => {
                  const label = context.dataset.label || "";
                  const value = context.parsed.y;
                  const text = value === 1 ? "consulta" : "consultas";
                  return `${label}: ${value} ${text}`;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Dia",
                font: { size: 18, weight: "bold" },
              },
              ticks: { font: { size: 14, weight: "bold" } },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Quantidade",
                font: { size: 18, weight: "bold" },
              },
              ticks: { stepSize: 1, font: { size: 14, weight: "bold" } },
            },
          },
        },
      }
    );
  }
}

function updateChartOrgHistory() {
  const filtered = applyFilters(
    billingsOrgHistoryData.value,
    activeFiltersOrgHistory.value
  );
  const { labels, datasets } = groupByMonthYearAndOrganization(filtered);

  if (chartOrgHistoryInstance) {
    chartOrgHistoryInstance.data.labels = labels;
    chartOrgHistoryInstance.data.datasets = datasets;
    chartOrgHistoryInstance.update();
  } else if (chartOrgHistory.value) {
    chartOrgHistoryInstance = new Chart(
      chartOrgHistory.value.getContext("2d")!,
      {
        type: "bar",
        data: { labels, datasets },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: { display: false },
            tooltip: {
              callbacks: {
                title: (context: any) => context[0].label,
                label: (context: any) => {
                  const label = context.dataset.label || "";
                  const value = context.parsed.y;
                  const text = value === 1 ? "consulta" : "consultas";
                  return `${label}: ${value} ${text}`;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Mês/Ano",
                font: { size: 18, weight: "bold" },
              },
              ticks: { font: { size: 14, weight: "bold" } },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Quantidade",
                font: { size: 18, weight: "bold" },
              },
              ticks: { font: { size: 14, weight: "bold" } },
            },
          },
        },
      }
    );
  }
}

const hasSerasaDataOrgMonth = computed(() => {
  return billingsOrgMonthData.value.some((billing) => billing.serasa);
});

const hasSerasaDataCompanyMonth = computed(() => {
  return billingsCompanyMonthData.value.some((billing) => billing.serasa);
});

const hasSerasaDataOrgHistory = computed(() => {
  return billingsOrgHistoryData.value.some((billing) => billing.serasa);
});

watch(activeFiltersOrgMonth, (newVal) => {
  if (newVal.some((item) => item !== "all") && newVal.includes("all")) {
    activeFiltersOrgMonth.value = newVal.filter((item) => item !== "all");
  }
});

watch(activeFiltersCompanyMonth, (newVal) => {
  if (newVal.some((item) => item !== "all") && newVal.includes("all")) {
    activeFiltersCompanyMonth.value = newVal.filter((item) => item !== "all");
  }
});

watch(activeFiltersOrgHistory, (newVal) => {
  if (newVal.some((item) => item !== "all") && newVal.includes("all")) {
    activeFiltersOrgHistory.value = newVal.filter((item) => item !== "all");
  }
});

watch(activeFiltersOrgMonth, () => updateChartOrgMonth());
watch(activeFiltersCompanyMonth, () => updateChartCompanyMonth());
watch(activeFiltersOrgHistory, () => updateChartOrgHistory());

function updateCharts() {
  updateChartOrgMonth();
  updateChartCompanyMonth();
  updateChartOrgHistory();
}

onMounted(() => {
  loadDataAndCreateCharts();
});
</script>

<style scoped>
.canvas-container {
  width: 100%;
  height: 400px;
  position: relative;
}

.chart-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.centered-title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
}

.current-month {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
}

.filter-toggle {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.filter-toggle .v-btn {
  text-transform: none;
  font-size: 14px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.filter-toggle .v-btn--active {
  background-color: #1976d2;
  color: #fff;
  border-color: #1976d2;
}
</style>

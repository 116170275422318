<template>
  <div style="display: flex">
    <div :id="'menu' + props.id" style="margin-right: 20px">
      <div>
        <span>SIGEF</span>
        <v-checkbox
          v-model="layers.sigef_margin.visible"
          :label="layers.sigef_margin.name"
          :color="layers.sigef_margin.color"
          @change="
            toggleLayerVisibility(
              layers.sigef_margin.id,
              layers.sigef_margin.visible
            )
          "
        />
      </div>
      <div>
        <span>CAR</span>
        <v-checkbox
          v-for="(layer, key) in visibleLayers.filter(
            (layer) => layer.id !== 'sigef_margin'
          )"
          :key="key"
          v-model="layer.visible"
          :label="layer.name"
          :color="layer.color"
          @change="toggleLayerVisibility(layer.id, layer.visible)"
        />
      </div>
    </div>
    <div
      :id="'map' + props.id"
      style="width: 100%; height: 500px; overflow: hidden; position: relative"
    ></div>
  </div>
</template>

<script setup lang="ts">
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { computed, onMounted, reactive } from "vue";

const props = defineProps<{
  sicars_geojson: any;
  sigef_area_geojson: any;
  id: number;
}>();

mapboxgl.accessToken =
  "pk.eyJ1IjoiZmFybWdvIiwiYSI6ImNtM2c1OWRpdzAwdXoya29pc3RwOGJqa3AifQ.Xgj6cUf7NFog1U4w0hIg4Q";

const layerConfig = {
  AREA_IMOVEL: { name: "Área Imóvel", color: "#607D8B", type: "fill" },
  APPS: { name: "APPs", color: "#2E7D32", type: "fill" },
  AREA_CONSOLIDADA: {
    name: "Área Consolidada",
    color: "#795548",
    type: "fill",
  },
  AREA_POUSIO: { name: "Área Pousio", color: "#FF9800", type: "fill" },
  RESERVA_LEGAL: { name: "Reserva Legal", color: "#1B5E20", type: "fill" },
  SERVIDAO_ADMINISTRATIVA: {
    name: "Servidão Administrativa",
    color: "#424242",
    type: "fill",
  },
  USO_RESTRITO: { name: "Uso Restrito", color: "#B9F6CA", type: "fill" },
  VEGETACAO_NATIVA: {
    name: "Vegetação Nativa",
    color: "#9E9D24",
    type: "fill",
  },
  HIDROGRAFIA: { name: "Hidrografia", color: "#0288D1", type: "fill" },
};

const layers = reactive({
  sigef_margin: {
    id: "sigef_margin",
    name: "Contorno SIGEF",
    visible: true,
    data: props.sigef_area_geojson,
    color: "#000000",
    type: "line",
  },
});

Object.entries(layerConfig).forEach(([key, config]) => {
  const normalData = props.sicars_geojson[key];
  const totalData = props.sicars_geojson[`${key}_TOTAL`];

  if (normalData) {
    layers[key.toLowerCase()] = {
      id: key.toLowerCase(),
      name: config.name,
      visible: true,
      data: { normal: normalData, total: totalData },
      color: config.color,
      type: config.type,
    };
  }
});

const visibleLayers = computed(() => Object.values(layers));

const toggleLayerVisibility = (layerId: string, isVisible: boolean) => {
  const visibility = isVisible ? "visible" : "none";

  if (map.getLayer(layerId)) {
    map.setLayoutProperty(layerId, "visibility", visibility);
  }

  if (map.getLayer(`${layerId}_fill`)) {
    map.setLayoutProperty(`${layerId}_fill`, "visibility", visibility);
  }

  if (map.getLayer(`${layerId}_transparent_fill`)) {
    map.setLayoutProperty(
      `${layerId}_transparent_fill`,
      "visibility",
      visibility
    );
  }

  if (map.getLayer(`${layerId}_outline`)) {
    map.setLayoutProperty(`${layerId}_outline`, "visibility", visibility);
  }
};

let map: mapboxgl.Map;

onMounted(() => {
  map = new mapboxgl.Map({
    container: "map" + props.id,
    style: "mapbox://styles/mapbox/streets-v12",
    center: props.sigef_area_geojson.properties.centroid,
    maxZoom: 18,
    minZoom: 13,
    zoom: 13,
    attributionControl: false,
  });

  map.on("load", () => {
    map.addControl(new mapboxgl.NavigationControl());

    map.addSource("sigef_margin", {
      type: "geojson",
      data: layers.sigef_margin.data,
    });

    map.addLayer({
      id: "sigef_margin",
      type: "line",
      source: "sigef_margin",
      paint: {
        "line-color": layers.sigef_margin.color,
        "line-width": 2,
      },
      layout: {
        visibility: layers.sigef_margin.visible ? "visible" : "none",
      },
    });

    Object.values(layers).forEach((layer) => {
      if (layer.data && layer.id !== "sigef_margin") {
        const layerFillId = `${layer.id}_fill`;
        map.addSource(layerFillId, {
          type: "geojson",
          data: layer.data.normal,
        });

        map.addLayer({
          id: layerFillId,
          type: "fill",
          source: layerFillId,
          paint: {
            "fill-color": layer.color,
            "fill-opacity": 0.5,
          },
          layout: {
            visibility: layer.visible ? "visible" : "none",
          },
        });

        if (layer.data.total) {
          const layerOutlineId = `${layer.id}_outline`;
          const layerTransparentFillId = `${layer.id}_transparent_fill`;

          map.addSource(layerOutlineId, {
            type: "geojson",
            data: layer.data.total,
          });

          map.addLayer({
            id: layerTransparentFillId,
            type: "fill",
            source: layerOutlineId,
            paint: {
              "fill-opacity": 0,
            },
            layout: {
              visibility: layer.visible ? "visible" : "none",
            },
          });

          map.addLayer({
            id: layerOutlineId,
            type: "line",
            source: layerOutlineId,
            paint: {
              "line-color": layer.color,
              "line-width": 2,
              "line-dasharray": [2, 2],
            },
            layout: {
              visibility: layer.visible ? "visible" : "none",
            },
          });

          const boundingBox = turf.bbox(props.sigef_area_geojson);
          map.fitBounds(boundingBox as [number, number, number, number], {
            padding: 100,
          });

          map.on("click", layerTransparentFillId, (e) => {
            const visibility = map.getLayoutProperty(
              layerTransparentFillId,
              "visibility"
            );

            if (visibility === "visible") {
              const features = map.queryRenderedFeatures(e.point, {
                layers: [layerTransparentFillId],
              });

              if (features.length) {
                const feature = features[0];

                const coordinates = e.lngLat;

                const codImovel = feature.properties?.cod_imovel ?? "N/A";
                let category = feature.properties?.category ?? "N/A";
                category = category.replace(/_TOTAL$/, "");
                const totalArea = feature.properties?.total_area ?? "N/A";

                const layerColor = layers[layer.id].color || "#000000";

                new mapboxgl.Popup({ closeButton: false })
                  .setLngLat([coordinates.lng, coordinates.lat])
                  .setHTML(
                    `<p>
                      <i class="mdi mdi-square-rounded v-icon" style="color: ${layerColor}; font-size: 24px;"></i>
                      <strong>Código CAR:</strong> ${codImovel}</p>
                    <p>
                    <strong>Categoria:</strong> ${category}
                    </p>
                    <p>
                      <strong>Área Total:</strong> ${totalArea} ha
                    </p>`
                  )
                  .addTo(map);
              }
            }
          });

          map.on("mouseenter", layerTransparentFillId, () => {
            map.getCanvas().style.cursor = "pointer";
          });

          map.on("mouseleave", layerTransparentFillId, () => {
            map.getCanvas().style.cursor = "";
          });
        }
      }
    });
  });
});
</script>

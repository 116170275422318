<template>
  <v-overlay
    id="overlayElement"
    v-model="loadingData"
    scroll-strategy="block"
    location-strategy="static"
    persistent
  >
    <div class="text-center">
      <v-progress-circular
        color="primary"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <p>Pesquisando dados...</p>
  </v-overlay>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <div
          class="d-flex flex-row mb-6 flex-column justify-center align-center"
        >
          <v-form v-model="form" @submit.prevent="search">
            <div class="d-flex flex-column">
              <v-autocomplete
                clearable
                label="Empresa"
                auto-select-first
                name="empresa"
                v-model="formData.company_id"
                autocomplete="off"
                :items="companies_select"
                item-title="name"
                item-value="id"
                validate-on="blur"
                :rules="[required]"
                class="mx-2 px-2"
              ></v-autocomplete>
              <v-combobox
                label="Tipo"
                name="type"
                v-model="formData.type"
                :items="types"
                :rules="[required]"
                class="mx-2 px-2"
              ></v-combobox>
              <v-text-field
                v-model="formData.document"
                @input="formatDocument"
                style="min-width: 500px"
                label="Documento"
                name="document"
                :rules="[required, checkDocument]"
                class="mx-2 px-2"
              ></v-text-field>
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-left: 20px;
                  gap: 30px;
                "
              >
                <v-switch
                  v-model="formData.judicial"
                  label="Processos Judiciais"
                  color="primary"
                ></v-switch>
                <v-switch
                  v-model="formData.rural"
                  label="Imóveis Rurais"
                  color="primary"
                ></v-switch>
              </div>
              <v-btn
                class="mx-2 px-2"
                color="primary"
                size="x-large"
                type="submit"
              >
                Pesquisar
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style lang="scss">
#overlayElement .v-overlay__content {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

@media print {
  table {
    page-break-inside: avoid;
  }
}
</style>

<script setup lang="ts">
import axios from "axios";
import { reactive } from "vue";
import { onBeforeMount, ref } from "vue";
import { required, validateCnpj, validateCpf } from "@/validators";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store";

const router = useRouter();

const types = ["CPF", "CNPJ"];
const companies_select: any[] = ref([]);

const form = ref(false);
const { showSnackbar } = useSnackbar();
const searchButtonDisabled = ref(false);
const loadingData = ref(false);

const cpf = ref("");
const cnpj = ref("");

const formData = reactive({
  company_id: undefined,
  type: "CPF",
  document: router.currentRoute.value.query.document || "",
  judicial: true,
  rural: true,
});

onBeforeMount(() => {
  axios
    .get("/companies")
    .then((res) => {
      companies_select.value = res.data;
    })
    .catch((err) => {
      companies_select.value = [];
    });
});

const formatDocument = (event: Event) => {
  if (formData.type == "CPF") {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, "");
    input.value = value
      .replace(/^(\d{3})(\d{3})/, "$1.$2.")
      .replace(/^(\d{3}\.\d{3}\.\d{3})(\d{1,2})/, "$1-$2");
    cpf.value = input.value;
  } else if (formData.type == "CNPJ") {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, "");
    input.value = value
      .replace(/^(\d{2})(\d{3})/, "$1.$2.")
      .replace(/^(\d{2}\.\d{3}\.\d{3})(\d{1,4})/, "$1/$2")
      .replace(/^(\d{2}\.\d{3}\.\d{3}\/\d{4})(\d{1,2})/, "$1-$2");
    cnpj.value = input.value;
  }
};

function checkDocument(v: string) {
  if (formData.type == "CPF") {
    return validateCpf(v);
  } else {
    return validateCnpj(v);
  }
}

function search() {
  if (!form.value) return;

  searchButtonDisabled.value = true;
  loadingData.value = true;

  axios
    .post("/search", formData)
    .then((res) => {
      showSnackbar("Pesquisa criada com sucesso.", "success");
      searchButtonDisabled.value = false;
      loadingData.value = false;
      router.push({
        name: "search_report",
        params: { search_id: res.data.id },
      });
    })
    .catch((err) => {
      searchButtonDisabled.value = false;
      loadingData.value = false;
      showSnackbar("Erro na Pesquisa.", "error");
    });
}
</script>

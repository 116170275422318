<template>
  <v-app-bar color="navbar" class="justify-center">
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    <v-app-bar-title
      style="cursor: pointer"
      @click="$router.push({ name: 'search' })"
      class="flex-0-0"
    >
      <v-img src="/static/assets/logo2.png" width="300"></v-img>
    </v-app-bar-title>
    <div class="d-flex align-center justify-end flex-grow-1 mr-3 text-h5">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-icon
            class="mx-2"
            v-bind="props"
            value="search"
            @click="$router.push({ name: 'search' })"
            active-color="primary"
          >
            mdi-account-search-outline
          </v-icon>
        </template>
        <span>Nova Consulta</span>
      </v-tooltip>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-icon
            class="mx-2"
            v-bind="props"
            value="searches"
            @click="$router.push({ name: 'searches' })"
            active-color="primary"
          >
            mdi-clipboard-text-search-outline
          </v-icon>
        </template>
        <span>Consultas</span>
      </v-tooltip>
      <v-menu v-model="menu" :close-on-content-click="true" offset-y>
        <template #activator="{ props }">
          <v-tooltip location="bottom">
            <template #activator="{ props: tooltipProps }">
              <v-icon class="mx-2" v-bind="{ ...props, ...tooltipProps }">
                mdi-account-cog-outline
              </v-icon>
            </template>
            <span>Configurações da Conta</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            prepend-icon="mdi-account"
            :subtitle="store.userMe?.email"
            :title="store.userMe?.full_name"
          ></v-list-item>
          <v-divider></v-divider>
          <v-list-item
            title="Alterar Senha"
            value="password"
            prepend-icon="mdi-lock"
            @click="
              $router.push({
                name: 'change_password',
                query: { user_id: store.userMe?.id },
              })
            "
          >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-icon
            class="mx-2"
            v-bind="props"
            value="sair"
            variant="tonal"
            @click="logout"
            >mdi-exit-to-app</v-icon
          ></template
        >
        <span>Sair</span>
      </v-tooltip>
    </div>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" temporary>
    <v-list nav>
      <v-list-item
        prepend-icon="mdi-account-search-outline"
        title="Nova Consulta"
        value="search"
        @click="$router.push({ name: 'search' })"
        active-color="primary"
      >
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-clipboard-text-search-outline"
        title="Consultas"
        value="searches"
        @click="$router.push({ name: 'searches' })"
        active-color="primary"
      >
      </v-list-item>
      <v-divider v-if="store.userMe?.is_admin"> </v-divider>
      <v-list-item
        v-if="store.userMe?.is_admin"
        prepend-icon="mdi-chart-bar"
        title="Dashboard"
        value="dashboard"
        @click="$router.push({ name: 'dashboard' })"
        active-color="primary"
      >
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        v-if="store.userMe?.is_admin"
        prepend-icon="mdi-badge-account-horizontal"
        title="Empresas"
        value="companies"
        @click="$router.push({ name: 'companies' })"
        active-color="primary"
      >
      </v-list-item>
      <v-list-item
        v-if="store.userMe?.is_master"
        prepend-icon="mdi-briefcase"
        title="Organizações"
        value="organizations"
        @click="$router.push({ name: 'organizations' })"
        active-color="primary"
      >
      </v-list-item>
      <v-list-item
        v-if="store.userMe?.is_admin"
        prepend-icon="mdi-account-group"
        title="Usuários"
        value="users"
        @click="$router.push({ name: 'users' })"
      ></v-list-item>
      <v-divider></v-divider>
      <v-list-item
        v-if="store.userMe?.is_master"
        prepend-icon="mdi-import"
        title="Importar SIGEF"
        value="import_sigef"
        @click="$router.push({ name: 'import_sigef' })"
      ></v-list-item>
      <v-list-item
        v-if="store.userMe?.is_master"
        prepend-icon="mdi-import"
        title="Importar SCNI"
        value="import_scni"
        @click="$router.push({ name: 'import_scni' })"
      ></v-list-item>
      <v-divider></v-divider>
      <v-list-item
        v-if="store.userMe?.is_master"
        prepend-icon="mdi-image-area"
        title="MapBiomas Imagem"
        value="mapbiomas"
        @click="$router.push({ name: 'mapbiomas' })"
      ></v-list-item>
      <v-list-item
        v-if="store.userMe?.is_master"
        prepend-icon="mdi-file-excel"
        title="MapBiomas CSV"
        value="import_csv_mapbiomas"
        @click="$router.push({ name: 'import_csv_mapbiomas' })"
      ></v-list-item>
      <v-divider></v-divider>
    </v-list>
  </v-navigation-drawer>
  <v-main style="height: 100%" class="bg-grey-lighten-3">
    <router-view />
  </v-main>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useUsersStore } from "@/modules/users/store";

const store = useUsersStore();
const router = useRouter();

const drawer = ref(false);
const menu = ref(false);

function logout() {
  axios.post("/logout").finally(() => {
    store.logout();
    router.push({ name: "login" });
  });
}
</script>

<style scoped lang="scss">
.v-application {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<template>
  <div class="px-4 py-2 d-flex justify-space-between">
    <v-combobox
      append-inner-icon="mdi-magnify"
      label="Filtrar"
      name="search"
      density="compact"
      v-model="search"
      hide-details
      single-line
      multiple
      chips
      autocomplete="off"
      style="max-width: 400px"
    ></v-combobox>
  </div>
  <v-data-table
    class="simple-table"
    :items="items"
    item-value="id"
    :headers="headers"
    :loading="loading"
    :search="searchQuery"
    :custom-filter="customFilter"
  >
    <template v-for="slot in Object.keys(slots)" v-slot:[slot]="{ item }">
      <slot :name="slot" :item="item"></slot>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        icon="mdi-share-variant"
        class="me-2"
        :data-tippy-content="tippyDetails"
        color="primary"
        @click="shareSearch(item.id)"
      ></v-icon>
      <v-icon
        icon="mdi-file"
        class="me-2"
        :data-tippy-content="tippyDetails"
        color="primary"
        @click="openDetails(item)"
      >
        <v-tooltip activator="parent" location="start">Teste</v-tooltip>
      </v-icon>
      <v-icon
        icon="mdi-cached"
        class="me-2"
        :data-tippy-content="tippyDetails"
        color="primary"
        @click="newSearch(item)"
      ></v-icon>
      <!-- <v-icon
        icon="mdi-delete"
        class="me-2"
        :data-tippy-content="tippyDetails"
        color="primary"
        @click="initDelete(item.id)"
      ></v-icon> -->
    </template>
  </v-data-table>

  <v-dialog v-model="showDeleteStep1" max-width="600px">
    <v-card>
      <v-card-title class="blue-title text-h6">
        <v-row class="pa-5 justify-end">
          <div class="d-flex align-center justify-start me-auto">
            <v-icon class="me-2">mdi-alert-outline</v-icon>
            Atenção
          </div>
          <v-btn
            size="small"
            icon
            color="grey-lighten-4"
            @click="cancelDeleteStep1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p class="text-body-1">
          Esta opção irá excluir os dados da consulta, impossibilitando sua
          recuperação no futuro. Esta ação não pode ser revertida.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancelDeleteStep1">Cancelar</v-btn>
        <v-btn color="primary" text @click="goToDeleteStep2">Avançar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showDeleteStep2" max-width="600px">
    <v-card>
      <v-card-title class="blue-title text-h6">
        <v-row class="pa-5 justify-end">
          <div class="d-flex align-center justify-start me-auto">
            <v-icon class="me-2">mdi-alert-outline</v-icon>
            Cuidado
          </div>
          <v-btn
            size="small"
            icon
            color="grey-lighten-4"
            @click="cancelDeleteStep2"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p class="text-body-1">
          Você tem certeza que deseja excluir os dados desta consulta? Após
          confirmar, não será possível recuperar ou visualizar essas informações
          novamente. Para confirmar, digite <strong>SIM</strong> no campo
          abaixo.
        </p>
        <v-text-field
          v-model="confirmText"
          label="Confirmação"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancelDeleteStep2">Cancelar</v-btn>
        <v-btn color="primary" text @click="confirmDeletion">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title class="blue-title text-h6">
        <v-row class="pa-5 justify-end">
          <div class="justify-start me-auto">Link compartilhado</div>
          <v-btn
            size="small"
            icon
            color="grey-lighten-4"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p class="text-body-1 font-weight-bold">URL de Compartilhamento</p>
        <v-text-field
          v-model="sharedUrl"
          readonly
          outlined
          dense
          append-icon="mdi-content-copy"
          @click:append="copyToClipboard"
        ></v-text-field>
        <p class="text-caption">
          <span class="text-caption-bold">Atenção:</span> a senha para acessar
          são os
          <span class="text-caption-bold">primeiros 4 dígitos do documento</span
          >.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Concluído</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, onBeforeMount, ref, useSlots } from "vue";
import tippy from "tippy.js";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store";

const dialog = ref(false);
const sharedUrl = ref("");

export interface Messages {
  unexpectedError: string;
}

interface Props {
  messages: Messages;
  urlBase: string;
  headers: any[];
  itemsProcess?: (items: any[]) => any[];
}

const router = useRouter();
const props = withDefaults(defineProps<Props>(), {});

const { showSnackbar } = useSnackbar();

const tippyDetails = "Detalhes";
const slots = useSlots();
const items = ref<any[]>([]);
const search = ref<string[]>([]);
const loading = ref(false);
const searchQuery = computed(() => search.value.join(","));

onBeforeMount(() => {
  getItems();
});

function customFilter(
  value: string | number,
  query: string
): boolean | number | [number, number] | [number, number][] {
  if (typeof value === "number") value = value.toString();
  const queries = query
    .trim()
    .split(",")
    .filter((q) => q)
    .map((q) => q.trim().toLocaleLowerCase());
  return (
    value != null &&
    query != null &&
    typeof value === "string" &&
    queries.every((q) =>
      value
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(q)
    )
  );
}

async function getItems() {
  try {
    loading.value = true;
    const res = await axios.get(props.urlBase);
    items.value = props.itemsProcess ? props.itemsProcess(res.data) : res.data;
    if (res.data.length !== items.value.length) {
      throw new Error(
        "CRUDTable: 'itemsProcess' returned different number of items from response"
      );
    }
  } catch (err) {
    showSnackbar(props.messages.unexpectedError, "error");
    console.error(err);
  } finally {
    tippy("[data-tippy-content]", { arrow: false });
    loading.value = false;
  }
}

function newSearch(item: any) {
  router.push({
    name: "search",
    query: { document: item.document },
  });
}

function openDetails(item: any) {
  router.push({ name: "search_report", params: { search_id: item.id } });
}

async function shareSearch(id_search: number) {
  try {
    const response = await axios.post(`/public/search`, null, {
      params: { id_search },
    });
    sharedUrl.value = response.data;
    dialog.value = true;
    showSnackbar("Link gerado com sucesso!", "success");
  } catch (error) {
    showSnackbar("Erro ao compartilhar a busca. Tente novamente.", "error");
    console.error("Erro ao compartilhar a busca:", error);
  }
}

const showDeleteStep1 = ref(false);
const showDeleteStep2 = ref(false);
const confirmText = ref("");
const pendingDeleteId = ref<number | null>(null);

function initDelete(id_search: number) {
  pendingDeleteId.value = id_search;
  showDeleteStep1.value = true;
}

function cancelDeleteStep1() {
  pendingDeleteId.value = null;
  showDeleteStep1.value = false;
}

function goToDeleteStep2() {
  showDeleteStep1.value = false;
  showDeleteStep2.value = true;
}

function cancelDeleteStep2() {
  pendingDeleteId.value = null;
  confirmText.value = "";
  showDeleteStep2.value = false;
}

async function confirmDeletion() {
  if (
    confirmText.value.trim().toUpperCase() === "SIM" &&
    pendingDeleteId.value !== null
  ) {
    try {
      await axios.delete("/search", {
        params: { id_search: pendingDeleteId.value },
      });
      showSnackbar("Consulta deletada com sucesso!", "success");
      items.value = items.value.filter(
        (item) => item.id !== pendingDeleteId.value
      );
    } catch (error) {
      showSnackbar("Erro ao deletar a consulta. Tente novamente.", "error");
      console.error("Erro ao deletar a consulta:", error);
    } finally {
      pendingDeleteId.value = null;
      confirmText.value = "";
      showDeleteStep2.value = false;
    }
  } else {
    showSnackbar("Texto de confirmação incorreto.", "error");
  }
}

function copyToClipboard() {
  navigator.clipboard.writeText(sharedUrl.value);
  showSnackbar("URL copiada para a área de transferência!", "success");
}
</script>

<style scoped lang="scss">
.simple-table::v-deep tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.blue-title {
  background-color: #1976d2;
  color: #ffffff;
  padding: 10px;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
}
.text-caption {
  color: #4caf50;
  font-weight: bold;
  font-size: 1rem !important;
}
.text-caption-bold {
  color: #388e3c;
  font-weight: bold;
  font-size: 1.125rem;
}
</style>

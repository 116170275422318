<template>
  <div>
    <v-card class="mt-5">
      <canvas ref="chart"></canvas>
    </v-card>
  </div>
</template>
<script setup lang="ts">
import Chart from "chart.js/auto";
import { onMounted, ref } from "vue";

interface CaptionData {
  label: string;
  data: Array<number>;
  backgroundColor: string;
}

interface BiomaCaptionData {
  labels: Array<string>;
  datasets: Array<CaptionData>;
}

const props = defineProps<{
  caption: BiomaCaptionData;
  colors: object;
}>();

const chart = ref(null);
const labels = ref(props.caption.labels);
const datasets = ref(props.caption.datasets);
onMounted(() => {
  const ctx = chart.value.getContext("2d");

  new Chart(ctx, {
    type: "bar",
    data: {
      labels: labels.value,
      datasets: datasets.value,
    },
    options: {
      responsive: true,
      indexAxis: "y",
      scales: {
        x: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
        y: {
          stacked: true,
          ticks: {
            padding: 40,
            autoSkip: true,
            maxTicksLimit: 10,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      },
      barThickness: 45,
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      },
    },
    plugins: [
      {
        id: "customCircleLabels",
        afterDraw: (chart) => {
          const ctx = chart.ctx;
          const yAxis = chart.scales.y;

          const labelColorMap = props.colors;

          if (chart.data.labels != undefined) {
            chart.data.labels.forEach((label, index) => {
              const color = labelColorMap[label];
              const yPos = yAxis.getPixelForTick(index);
              const xPos = chart.chartArea.left - 20;

              ctx.beginPath();
              ctx.arc(xPos, yPos, 10, 0, 5 * Math.PI);
              ctx.fillStyle = color;
              ctx.fill();
              ctx.closePath();
            });
          }
        },
      },
    ],
  });
});
</script>

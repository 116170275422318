export function decimal(v: string) {
  const test = /^\d*(\.\d{0,2})?$/.test(v);
  return test || "Número inválido. Formato esperado: 1,23";
}

export function required(v: any) {
  return (typeof v == "number" && v == 0) || !!v || "Campo obrigatório";
}

export function requiredMultiple(v: any) {
  return v.length > 0 || "Campo obrigatório";
}

export function validateEmail(v: string) {
  const test = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v);
  return test || "Email inválido. Formato esperado: seu@email.com";
}

export function validateCpf(v: string) {
  const test = /^(\d{3}\.\d{3}\.\d{3}-\d{2})$/.test(v);

  if (test == false) {
    return "CPF inválido. Formato esperado: XXX.XXX.XXX-XX";
  }

  return validCpf(v);
}

function validCpf(v: string) {
  const cpf = v.replace(/[^\d]+/g, "");

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let firstDigit = 11 - (sum % 11);
  if (firstDigit >= 10) firstDigit = 0;

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  let secondDigit = 11 - (sum % 11);
  if (secondDigit >= 10) secondDigit = 0;

  const check =
    cpf.charAt(9) == firstDigit.toString() &&
    cpf.charAt(10) == secondDigit.toString();

  return check || "O CPF digitado é inválido!";
}

export function validateCnpj(v: string) {
  const test = /^(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/.test(v);

  if (test == false) {
    return "CNPJ inválido. Formato esperado: XX.XXX.XXX/XXXX-XX";
  }

  return checkCnpj(v);
}

function checkCnpj(v: string) {
  const cnpj = v.replace(/[^\d]+/g, "");

  const firstWeights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let soma = 0;
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj.charAt(i)) * firstWeights[i];
  }
  let firstDigit = 11 - (soma % 11);
  if (firstDigit >= 10) firstDigit = 0;

  const secondWeights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  soma = 0;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj.charAt(i)) * secondWeights[i];
  }
  let secondDigit = 11 - (soma % 11);
  if (secondDigit >= 10) secondDigit = 0;

  const check =
    cnpj.charAt(12) == firstDigit.toString() &&
    cnpj.charAt(13) == secondDigit.toString();

  return check || "O CNPJ digitado é inválido!";
}

export function validatePassword(v: string) {
  if (!v) return true;
  const re = /^(?=.*\d)(?=.*[!@#$%^&*.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return (
    re.test(v) ||
    "A Senha deve conter 8 ou mais caracteres, com pelo menos uma letra maiúscula, uma minúscula, um caractere especial (!@#$%^&*.) e um número. Ex: Abcd531@"
  );
}

export function minValue(min: number) {
  return (v: number) => v >= min || `Deve ser maior ou igual a ${min}`;
}

export function checkSerasa(v: string) {
  console.log(v);
  return v || false;
}

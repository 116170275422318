<template>
  <div class="d-flex w-100 h-100 align-center justify-center">
    <div>
      <iframe
        :src="url"
        frameborder="0"
        width="1200"
        height="1700"
        allowtransparency
      >
      </iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useSnackbar } from "@/store";

const route = useRoute();

const organizationId = route.params.organization_id as string;
const { showSnackbar } = useSnackbar();

const url = ref<string>("");

let params = {
  id_organization: organizationId,
};

onBeforeMount(() => {
  loadMetabase();
});

function loadMetabase() {
  axios
    .get("/metabase", { params })
    .then((res) => {
      url.value = res.data;
    })
    .catch((err) => {
      let errorMessage = "Erro no carrgamento do Metabase";
      showSnackbar(errorMessage, "error");
    });
}
</script>

<template>
  <div class="my-5">
    <v-card class="mt-5">
      <v-card-title class="text-h5 bg-primary font-weight-bold">
        Processos Judiciais
      </v-card-title>
      <div class="mx-3 my-2">
        <div v-if="paginatedDemandantData.length > 0">
          <h3>
            Como demandante (R$
            {{ SimpleMaskMoney.formatToCurrency(props.data.demandant_total) }})
          </h3>
          <v-table>
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="header"
                  :class="{
                    'right-align': isRight(rightHeader, header),
                    'cnj-column': index === 0,
                  }"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, rowIndex) in paginatedDemandantData"
                :key="rowIndex"
              >
                <td
                  v-for="(cell, cellIndex) in row"
                  :key="cellIndex"
                  :class="{
                    'right-align': cellIndex === 8,
                    'cnj-column': cellIndex === 0,
                  }"
                >
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </v-table>
          <div class="mt-2 d-flex align-center justify-end">
            <v-btn
              text
              color="primary"
              @click="prevDemandantPage"
              :disabled="pageDemandant === 1"
            >
              Anterior
            </v-btn>
            <span class="mx-2">
              Página {{ pageDemandant }} de {{ totalPagesDemandant }}
            </span>
            <v-btn
              text
              color="primary"
              @click="nextDemandantPage"
              :disabled="pageDemandant === totalPagesDemandant"
            >
              Próximo
            </v-btn>
          </div>
        </div>
        <div v-else>
          <p>Sem processos como demandante!</p>
        </div>
        <div class="my-3"></div>
        <div v-if="paginatedDefendantData.length > 0">
          <h3>
            Como demandado (R$
            {{ SimpleMaskMoney.formatToCurrency(props.data.defendant_total) }})
          </h3>
          <v-table>
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="header"
                  :class="{
                    'right-align': isRight(rightHeader, header),
                    'cnj-column': index === 0,
                  }"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, rowIndex) in paginatedDefendantData"
                :key="rowIndex"
              >
                <td
                  v-for="(cell, cellIndex) in row"
                  :key="cellIndex"
                  :class="{
                    'right-align': cellIndex === 8,
                    'cnj-column': cellIndex === 0,
                  }"
                >
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </v-table>
          <div class="mt-2 d-flex align-center justify-end">
            <v-btn
              text
              color="primary"
              @click="prevDefendantPage"
              :disabled="pageDefendant === 1"
            >
              Anterior
            </v-btn>
            <span class="mx-2">
              Página {{ pageDefendant }} de {{ totalPagesDefendant }}
            </span>
            <v-btn
              text
              color="primary"
              @click="nextDefendantPage"
              :disabled="pageDefendant === totalPagesDefendant"
            >
              Próximo
            </v-btn>
          </div>
        </div>
        <div v-else>
          <p>Sem processos como demandado!</p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import SimpleMaskMoney from "simple-mask-money";

interface Table {
  rows: Array<object>;
}

interface JudicialData {
  demandant_total: string;
  demandant_data: Table;
  defendant_total: string;
  defendant_data: Table;
}

const props = defineProps<{
  data: JudicialData;
}>();

const rightHeader = ["Vlr (R$)", "value"];
const headers = [
  "CNJ",
  "Demandante",
  "Demandado",
  "Assunto",
  "Início",
  "Origem",
  "Status Predito",
  "Data Distrib.",
  "Vlr (R$)",
  "Sigilo",
];

function isRight(columns: string[], value: string) {
  return columns.includes(value);
}

const pageDemandant = ref(1);
const itemsPerPage = 5;
const totalPagesDemandant = computed(() => {
  return Math.ceil(props.data.demandant_data.rows.length / itemsPerPage);
});
const paginatedDemandantData = computed(() => {
  const startIndex = (pageDemandant.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return props.data.demandant_data.rows.slice(startIndex, endIndex);
});
function nextDemandantPage() {
  if (pageDemandant.value < totalPagesDemandant.value) pageDemandant.value++;
}
function prevDemandantPage() {
  if (pageDemandant.value > 1) pageDemandant.value--;
}

const pageDefendant = ref(1);
const totalPagesDefendant = computed(() => {
  return Math.ceil(props.data.defendant_data.rows.length / itemsPerPage);
});
const paginatedDefendantData = computed(() => {
  const startIndex = (pageDefendant.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return props.data.defendant_data.rows.slice(startIndex, endIndex);
});
function nextDefendantPage() {
  if (pageDefendant.value < totalPagesDefendant.value) pageDefendant.value++;
}
function prevDefendantPage() {
  if (pageDefendant.value > 1) pageDefendant.value--;
}
</script>

<style scoped>
.right-align {
  text-align: right;
}

.cnj-column {
  width: 12vw;
}
</style>

<template>
  <div class="my-5">
    <v-card>
      <v-card-title class="text-h5 bg-primary font-weight-bold">
        Protestos
      </v-card-title>
      <div class="my-2 mx-3" v-if="paginatedData.length > 0">
        <p><span style="font-weight: bold">Total:</span> {{ props.total }}</p>
        <table>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header"
                :class="{
                  'right-align': isRight(rightHeader, header),
                }"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in paginatedData" :key="rowIndex">
              <td
                v-for="(cell, cellIndex) in row"
                :key="cellIndex"
                :class="{
                  'right-align': isRight(rightHeader, cellIndex.toString()),
                }"
              >
                {{ cell }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-2 d-flex align-center justify-end">
          <v-btn text color="primary" @click="prevPage" :disabled="page === 1">
            Anterior
          </v-btn>
          <span class="mx-2"> Página {{ page }} de {{ totalPages }} </span>
          <v-btn
            text
            color="primary"
            @click="nextPage"
            :disabled="page === totalPages"
          >
            Próximo
          </v-btn>
        </div>
      </div>
      <div class="my-2 mx-3" v-else>Sem protestos encontrados!</div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

const props = defineProps<{
  total: string;
  data: Array<object>;
}>();

const rightHeader = ["Valor", "value"];
const headers = ["Data", "Cartório", "Cidade/UF", "Valor"];

function isRight(columns: string[], value: string) {
  return columns.includes(value);
}

const itemsPerPage = 5;
const page = ref(1);
const totalPages = computed(() => Math.ceil(props.data.length / itemsPerPage));
const paginatedData = computed(() => {
  const startIndex = (page.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return props.data.slice(startIndex, endIndex);
});

function nextPage() {
  if (page.value < totalPages.value) {
    page.value++;
  }
}
function prevPage() {
  if (page.value > 1) {
    page.value--;
  }
}
</script>

<style scoped>
.right-align {
  text-align: right;
}
</style>

<template>
  <v-overlay
    id="overlayElement"
    v-model="loadingData"
    scroll-strategy="block"
    location-strategy="static"
    persistent
  >
    <div class="text-center">
      <v-progress-circular
        color="primary"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <p>Enviando dados...</p>
  </v-overlay>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <div
          class="d-flex flex-row mb-6 flex-column justify-center align-center"
        >
          <v-form v-model="form" @submit.prevent="submitFile">
            <v-text-field
              label="Coleção"
              name="collection"
              v-model="collection"
              validate-on="blur"
              :rules="[required]"
              autofocus
              ref="nameInput"
            ></v-text-field>
            <div class="d-flex flex-column">
              <v-file-input
                accept=".csv"
                label="Arquivo CSV"
                class="mx-2 px-2"
                style="min-width: 500px"
                v-model="excelFile"
                :rules="[requiredFile]"
              >
              </v-file-input>
              <v-btn
                class="mx-2 px-2"
                color="primary"
                size="x-large"
                type="submit"
              >
                Enviar
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style lang="scss">
#overlayElement .v-overlay__content {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";
import { required } from "@/validators";
import { useSnackbar } from "@/store";

const collection = ref("");
const excelFile = ref();
const form = ref(false);
const { showSnackbar } = useSnackbar();
const searchButtonDisabled = ref(false);
const loadingData = ref(false);

function requiredFile(v: any) {
  return v.length == 1 || "Selecione um arquivo CSV";
}

async function submitFile() {
  if (!excelFile.value) return;
  searchButtonDisabled.value = true;
  loadingData.value = true;

  try {
    const formData = new FormData();
    formData.append("collection", collection.value);
    formData.append("file", excelFile.value[0]);
    await axios.post("/import_mapbiomas_csv", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    showSnackbar("Arquivo CSV importado com sucesso.", "success");
    collection.value = "";
    excelFile.value = null;
    searchButtonDisabled.value = false;
    loadingData.value = false;
  } catch (err) {
    showSnackbar("Erro no envio do arquivo.", "error");
    searchButtonDisabled.value = false;
    loadingData.value = false;
  }
}
</script>
